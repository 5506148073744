.snackbar-container {
  color: #fff;
  opacity: 0;
  z-index: 9999;
  background-color: #070b0e;
  justify-content: space-between;
  align-items: center;
  min-height: 14px;
  padding: 18px 24px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 22px;
  transition: top .5s, right .5s, bottom .5s, left .5s, opacity .5s;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
}

.snackbar-container.has-snackbar-action {
  cursor: pointer;
}

.snackbar-container .action {
  background: inherit;
  font-size: inherit;
  text-transform: uppercase;
  color: #4caf50;
  cursor: pointer;
  border: none;
  min-width: min-content;
  margin: 0 0 0 24px;
  padding: 0;
  display: inline-block;
}

@media (width >= 640px) {
  .snackbar-container {
    border-radius: 2px;
    min-width: 288px;
    max-width: 568px;
    margin: 24px;
    display: inline-flex;
    bottom: -100px;
  }
}

@media (width <= 640px) {
  .snackbar-container {
    left: 0;
    right: 0;
  }
}

.snackbar-pos.bottom-center {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  top: auto !important;
}

.snackbar-pos.bottom-left {
  bottom: 0;
  left: 0;
  top: auto !important;
}

.snackbar-pos.bottom-right {
  bottom: 0;
  right: 0;
  top: auto !important;
}

.snackbar-pos.top-left {
  top: 0;
  left: 0;
  bottom: auto !important;
}

.snackbar-pos.top-center {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: auto !important;
}

.snackbar-pos.top-right {
  top: 0;
  right: 0;
  bottom: auto !important;
}

.snackbar-icon {
  margin-right: 20px;
  font-size: 3em;
}

.snackbar-close-button {
  margin-left: 20px;
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-content:after, .simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-height-auto-observer-wrapper {
  float: left;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  max-width: 1px;
  height: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  width: 1000%;
  min-width: 1px;
  height: 1000%;
  min-height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  width: auto;
  min-width: 10px;
  height: 7px;
  min-height: 0;
  top: 2px;
  left: 0;
  right: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  opacity: 0;
  visibility: hidden;
  direction: rtl;
  width: 500px;
  height: 500px;
  position: fixed;
  overflow: scroll hidden;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.is-image-replaced {
  text-align: left;
  text-indent: -9999px;
  white-space: nowrap;
  background-color: #0000;
  background-repeat: no-repeat;
  border: 0;
  display: block;
  overflow: hidden;
}

.m-0 {
  margin: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.m-90 {
  margin: 90px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.m-100 {
  margin: 100px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.m-lh {
  margin: 1.5em !important;
}

.my-lh {
  margin-top: 1.5em !important;
  margin-bottom: 1.5em !important;
}

.mx-lh {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}

.p-lh {
  padding: 1.5em !important;
}

.py-lh {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
}

.px-lh {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

.mt-lh {
  margin-top: 1.5em !important;
}

.pt-lh {
  padding-top: 1.5em !important;
}

.mb-lh, .story__text p {
  margin-bottom: 1.5em !important;
}

.pb-lh {
  padding-bottom: 1.5em !important;
}

.ml-lh {
  margin-left: 1.5em !important;
}

.pl-lh {
  padding-left: 1.5em !important;
}

.mr-lh {
  margin-right: 1.5em !important;
}

.pr-lh {
  padding-right: 1.5em !important;
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("nunito-sans-v12-latin-regular.67acc80e.woff2") format("woff2"), url("nunito-sans-v12-latin-regular.43000853.woff") format("woff");
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("nunito-sans-v12-latin-italic.2d9f2d66.woff2") format("woff2"), url("nunito-sans-v12-latin-italic.bdc4b89a.woff") format("woff");
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("nunito-sans-v12-latin-700.5961b4db.woff2") format("woff2"), url("nunito-sans-v12-latin-700.f9e1b341.woff") format("woff");
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("nunito-sans-v12-latin-700italic.540442c2.woff2") format("woff2"), url("nunito-sans-v12-latin-700italic.96ae8cad.woff") format("woff");
}

@font-face {
  font-family: Gelasio;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("gelasio-v10-latin-regular.d24da606.woff2") format("woff2"), url("gelasio-v10-latin-regular.982a2a53.woff") format("woff");
}

@font-face {
  font-family: Gelasio;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("gelasio-v10-latin-italic.465ab112.woff2") format("woff2"), url("gelasio-v10-latin-italic.f3a337ab.woff") format("woff");
}

@font-face {
  font-family: Gelasio;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("gelasio-v10-latin-700.6b90bd8f.woff2") format("woff2"), url("gelasio-v10-latin-700.fe96c44b.woff") format("woff");
}

@font-face {
  font-family: Gelasio;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("gelasio-v10-latin-700italic.5d8c23a9.woff2") format("woff2"), url("gelasio-v10-latin-700italic.4267d0af.woff") format("woff");
}

@keyframes test-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    -webkit-user-select: auto;
    user-select: auto;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes test-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes loading-spinner-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes loading-spinner-pulsate {
  0%, 100% {
    transform: translate(-50%, -50%)scale(.9);
  }

  50% {
    transform: translate(-50%, -50%)scale(1);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.file, .button, .is-unselectable, .modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
  content: " ";
  pointer-events: none;
  transform-origin: center;
  border-top: 0;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  border-right: 0;
  border-radius: 2px;
  width: .625em;
  height: .625em;
  margin-top: -.4375em;
  display: block;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
}

.notification:not(:last-child), .box:not(:last-child) {
  margin-bottom: 1.5rem;
}

.modal-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  pointer-events: auto;
  vertical-align: top;
  background-color: #0003;
  border: none;
  border-radius: 290486px;
  outline: none;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.modal-close:before, .modal-close:after {
  content: "";
  transform-origin: center;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg);
}

.modal-close:before {
  width: 50%;
  height: 2px;
}

.modal-close:after {
  width: 2px;
  height: 50%;
}

.modal-close:hover, .modal-close:focus {
  background-color: #0000004d;
}

.modal-close:active {
  background-color: #0006;
}

.is-small.modal-close {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.is-medium.modal-close {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.is-large.modal-close {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.control.is-loading:after, .select.is-loading:after, .button.is-loading:after {
  content: "";
  border: 2px solid #0000;
  border-color: #0000 #0000 #e6e6e6 #e6e6e6;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.modal-background, .modal, .is-overlay {
  position: absolute;
  inset: 0;
}

.file-cta, .file-name, .select select, .textarea, .input, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 2px solid #0000;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  height: 2.25em;
  padding: calc(.375em - 2px) calc(.75em - 2px);
  font-size: 1.6rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.file-cta:focus, .file-name:focus, .select select:focus, .textarea:focus, .input:focus, .button:focus, .is-focused.file-cta, .is-focused.file-name, .select select.is-focused, .is-focused.textarea, .is-focused.input, .is-focused.button, .file-cta:active, .file-name:active, .select select:active, .textarea:active, .input:active, .button:active, .is-active.file-cta, .is-active.file-name, .select select.is-active, .is-active.textarea, .is-active.input, .is-active.button {
  outline: none;
}

[disabled].file-cta, [disabled].file-name, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button, fieldset[disabled] .file-cta, fieldset[disabled] .file-name, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input, fieldset[disabled] .button {
  cursor: not-allowed;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: left;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fff;
  min-width: 300px;
  font-size: 10px;
  overflow: hidden scroll;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, select, textarea {
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: Consolas, Monaco, monospace;
}

body {
  color: #000;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #e78704;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #000;
}

code {
  color: #d3007e;
  background-color: #fff;
  padding: .25em .5em;
  font-size: .833333em;
  font-weight: normal;
}

hr {
  background-color: #e6e6e6;
  border: none;
  height: 2px;
  margin: 1.5rem 0;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .833333em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #000;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #000;
  white-space: pre;
  word-wrap: normal;
  background-color: #fff;
  padding: 1.25rem 1.5rem;
  font-size: .833333em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: left;
}

table th {
  color: #000;
}

.is-clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

.is-clipped {
  overflow: hidden !important;
}

.is-size-1 {
  font-size: 3.2rem !important;
}

.is-size-2 {
  font-size: 2.4rem !important;
}

.is-size-3 {
  font-size: 1.8rem !important;
}

.is-size-4 {
  font-size: 1.6rem !important;
}

.is-size-5 {
  font-size: 1.4rem !important;
}

.is-size-6 {
  font-size: 1.2rem !important;
}

.is-size-7 {
  font-size: 1rem !important;
}

@media screen and (width <= 768px) {
  .is-size-1-mobile {
    font-size: 3.2rem !important;
  }

  .is-size-2-mobile {
    font-size: 2.4rem !important;
  }

  .is-size-3-mobile {
    font-size: 1.8rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.6rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.4rem !important;
  }

  .is-size-6-mobile {
    font-size: 1.2rem !important;
  }

  .is-size-7-mobile {
    font-size: 1rem !important;
  }
}

@media screen and (width >= 769px), print {
  .is-size-1-tablet {
    font-size: 3.2rem !important;
  }

  .is-size-2-tablet {
    font-size: 2.4rem !important;
  }

  .is-size-3-tablet {
    font-size: 1.8rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.6rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.4rem !important;
  }

  .is-size-6-tablet {
    font-size: 1.2rem !important;
  }

  .is-size-7-tablet {
    font-size: 1rem !important;
  }
}

@media screen and (width <= 979px) {
  .is-size-1-touch {
    font-size: 3.2rem !important;
  }

  .is-size-2-touch {
    font-size: 2.4rem !important;
  }

  .is-size-3-touch {
    font-size: 1.8rem !important;
  }

  .is-size-4-touch {
    font-size: 1.6rem !important;
  }

  .is-size-5-touch {
    font-size: 1.4rem !important;
  }

  .is-size-6-touch {
    font-size: 1.2rem !important;
  }

  .is-size-7-touch {
    font-size: 1rem !important;
  }
}

@media screen and (width >= 980px) {
  .is-size-1-desktop {
    font-size: 3.2rem !important;
  }

  .is-size-2-desktop {
    font-size: 2.4rem !important;
  }

  .is-size-3-desktop {
    font-size: 1.8rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.6rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.4rem !important;
  }

  .is-size-6-desktop {
    font-size: 1.2rem !important;
  }

  .is-size-7-desktop {
    font-size: 1rem !important;
  }
}

@media screen and (width >= 1172px) {
  .is-size-1-widescreen {
    font-size: 3.2rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2.4rem !important;
  }

  .is-size-3-widescreen {
    font-size: 1.8rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.6rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.4rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1.2rem !important;
  }

  .is-size-7-widescreen {
    font-size: 1rem !important;
  }
}

@media screen and (width >= 1364px) {
  .is-size-1-fullhd {
    font-size: 3.2rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2.4rem !important;
  }

  .is-size-3-fullhd {
    font-size: 1.8rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.6rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.4rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1.2rem !important;
  }

  .is-size-7-fullhd {
    font-size: 1rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (width <= 768px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (width <= 979px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (width >= 980px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1172px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (width >= 1364px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (width <= 979px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (width >= 980px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1172px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (width >= 1364px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (width <= 979px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (width >= 980px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1172px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (width >= 1364px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (width <= 768px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (width >= 769px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (width <= 979px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (width >= 980px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1172px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (width >= 1364px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.has-text-white {
  color: #fff !important;
}

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important;
}

.has-background-white {
  background-color: #fff !important;
}

.has-text-black, a.has-text-black:hover, a.has-text-black:focus {
  color: #000 !important;
}

.has-background-black {
  background-color: #000 !important;
}

.has-text-light {
  color: #fff !important;
}

a.has-text-light:hover, a.has-text-light:focus {
  color: #e6e6e6 !important;
}

.has-background-light {
  background-color: #fff !important;
}

.has-text-dark, a.has-text-dark:hover, a.has-text-dark:focus {
  color: #000 !important;
}

.has-background-dark {
  background-color: #000 !important;
}

.has-text-primary {
  color: #e78704 !important;
}

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #b56a03 !important;
}

.has-background-primary {
  background-color: #e78704 !important;
}

.has-text-link {
  color: #e78704 !important;
}

a.has-text-link:hover, a.has-text-link:focus {
  color: #b56a03 !important;
}

.has-background-link {
  background-color: #e78704 !important;
}

.has-text-info {
  color: #00b3cc !important;
}

a.has-text-info:hover, a.has-text-info:focus {
  color: #008699 !important;
}

.has-background-info {
  background-color: #00b3cc !important;
}

.has-text-success {
  color: #5bc72b !important;
}

a.has-text-success:hover, a.has-text-success:focus {
  color: #489d22 !important;
}

.has-background-success {
  background-color: #5bc72b !important;
}

.has-text-warning {
  color: #d3007e !important;
}

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #a00060 !important;
}

.has-background-warning {
  background-color: #d3007e !important;
}

.has-text-danger {
  color: #d30000 !important;
}

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #a00000 !important;
}

.has-background-danger {
  background-color: #d30000 !important;
}

.has-text-black-bis {
  color: #1a1a1a !important;
}

.has-background-black-bis {
  background-color: #1a1a1a !important;
}

.has-text-black-ter {
  color: #404040 !important;
}

.has-background-black-ter {
  background-color: #404040 !important;
}

.has-text-grey-darker {
  color: #1a1a1a !important;
}

.has-background-grey-darker {
  background-color: #1a1a1a !important;
}

.has-text-grey-dark {
  color: #404040 !important;
}

.has-background-grey-dark {
  background-color: #404040 !important;
}

.has-text-grey {
  color: #7f7f7f !important;
}

.has-background-grey {
  background-color: #7f7f7f !important;
}

.has-text-grey-light {
  color: #bfbfbf !important;
}

.has-background-grey-light {
  background-color: #bfbfbf !important;
}

.has-text-grey-lighter {
  color: #e6e6e6 !important;
}

.has-background-grey-lighter {
  background-color: #e6e6e6 !important;
}

.has-text-white-ter {
  color: #f2f2f2 !important;
}

.has-background-white-ter {
  background-color: #f2f2f2 !important;
}

.has-text-white-bis {
  color: #e6e6e6 !important;
}

.has-background-white-bis {
  background-color: #e6e6e6 !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary {
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-secondary {
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif !important;
}

.is-family-sans-serif {
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: Consolas, Monaco, monospace !important;
}

.is-block {
  display: block !important;
}

@media screen and (width <= 768px) {
  .is-block-mobile {
    display: block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-block-tablet {
    display: block !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-block-tablet-only {
    display: block !important;
  }
}

@media screen and (width <= 979px) {
  .is-block-touch {
    display: block !important;
  }
}

@media screen and (width >= 980px) {
  .is-block-desktop {
    display: block !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-block-desktop-only {
    display: block !important;
  }
}

@media screen and (width >= 1172px) {
  .is-block-widescreen {
    display: block !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-block-widescreen-only {
    display: block !important;
  }
}

@media screen and (width >= 1364px) {
  .is-block-fullhd {
    display: block !important;
  }
}

.is-flex {
  display: flex !important;
}

@media screen and (width <= 768px) {
  .is-flex-mobile {
    display: flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-flex-tablet {
    display: flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-flex-tablet-only {
    display: flex !important;
  }
}

@media screen and (width <= 979px) {
  .is-flex-touch {
    display: flex !important;
  }
}

@media screen and (width >= 980px) {
  .is-flex-desktop {
    display: flex !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-flex-desktop-only {
    display: flex !important;
  }
}

@media screen and (width >= 1172px) {
  .is-flex-widescreen {
    display: flex !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-flex-widescreen-only {
    display: flex !important;
  }
}

@media screen and (width >= 1364px) {
  .is-flex-fullhd {
    display: flex !important;
  }
}

.is-inline {
  display: inline !important;
}

@media screen and (width <= 768px) {
  .is-inline-mobile {
    display: inline !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-tablet {
    display: inline !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-inline-tablet-only {
    display: inline !important;
  }
}

@media screen and (width <= 979px) {
  .is-inline-touch {
    display: inline !important;
  }
}

@media screen and (width >= 980px) {
  .is-inline-desktop {
    display: inline !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-inline-desktop-only {
    display: inline !important;
  }
}

@media screen and (width >= 1172px) {
  .is-inline-widescreen {
    display: inline !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-inline-widescreen-only {
    display: inline !important;
  }
}

@media screen and (width >= 1364px) {
  .is-inline-fullhd {
    display: inline !important;
  }
}

.is-inline-block {
  display: inline-block !important;
}

@media screen and (width <= 768px) {
  .is-inline-block-mobile {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-inline-block-tablet-only {
    display: inline-block !important;
  }
}

@media screen and (width <= 979px) {
  .is-inline-block-touch {
    display: inline-block !important;
  }
}

@media screen and (width >= 980px) {
  .is-inline-block-desktop {
    display: inline-block !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-inline-block-desktop-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1172px) {
  .is-inline-block-widescreen {
    display: inline-block !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important;
  }
}

@media screen and (width >= 1364px) {
  .is-inline-block-fullhd {
    display: inline-block !important;
  }
}

.is-inline-flex {
  display: inline-flex !important;
}

@media screen and (width <= 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important;
  }
}

@media screen and (width <= 979px) {
  .is-inline-flex-touch {
    display: inline-flex !important;
  }
}

@media screen and (width >= 980px) {
  .is-inline-flex-desktop {
    display: inline-flex !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1172px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1364px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important;
  }
}

.is-hidden, .qanda__complete .qanda__complete__button--hidden, .header__launch__stop--hidden, .header__launch__start--hidden {
  display: none !important;
}

.is-sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: none !important;
  width: .01em !important;
  height: .01em !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media screen and (width <= 768px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

@media screen and (width >= 769px), print {
  .is-hidden-tablet {
    display: none !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

@media screen and (width <= 979px) {
  .is-hidden-touch {
    display: none !important;
  }
}

@media screen and (width >= 980px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (width >= 1172px) {
  .is-hidden-widescreen {
    display: none !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-hidden-widescreen-only {
    display: none !important;
  }
}

@media screen and (width >= 1364px) {
  .is-hidden-fullhd {
    display: none !important;
  }
}

.is-invisible, .qanda__complete.qanda__complete--invisible, .qanda__backforward .qanda__forward--invisible, .qanda__backforward .qanda__back--invisible {
  visibility: hidden !important;
}

@media screen and (width <= 768px) {
  .is-invisible-mobile {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .is-invisible-tablet-only {
    visibility: hidden !important;
  }
}

@media screen and (width <= 979px) {
  .is-invisible-touch {
    visibility: hidden !important;
  }
}

@media screen and (width >= 980px) {
  .is-invisible-desktop {
    visibility: hidden !important;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .is-invisible-desktop-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1172px) {
  .is-invisible-widescreen {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important;
  }
}

@media screen and (width >= 1364px) {
  .is-invisible-fullhd {
    visibility: hidden !important;
  }
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-relative {
  position: relative !important;
}

.box {
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: block;
  box-shadow: 0 0 20px #6a3e021a;
}

a.box:hover, a.box:focus {
  box-shadow: 0 2px 3px #0000001a, 0 0 0 1px #e78704;
}

a.box:active {
  box-shadow: inset 0 1px 2px #0003, 0 0 0 1px #e78704;
}

.button {
  color: #e78704;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-width: 0;
  border-color: #0000;
  justify-content: center;
  padding: calc(.5em - 0) .75em;
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.button strong {
  color: inherit;
}

.button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
  width: 1.5em;
  height: 1.5em;
}

.button .icon:first-child:not(:last-child) {
  margin-left: calc(-.375em - 0);
  margin-right: .1875em;
}

.button .icon:last-child:not(:first-child) {
  margin-left: .1875em;
  margin-right: calc(-.375em - 0);
}

.button .icon:first-child:last-child {
  margin-left: calc(-.375em - 0);
  margin-right: calc(-.375em - 0);
}

.button:hover, .button.is-hovered, .button:focus, .button.is-focused {
  color: #e78704;
  border-color: #0000;
}

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: 0 none;
}

.button:active, .button.is-active {
  color: #e78704;
  border-color: #0000;
}

.button.is-text {
  color: #e78704;
  background-color: #0000;
  border-color: #0000;
  text-decoration: underline;
}

.button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused, .button.is-text:active, .button.is-text.is-active {
  color: #e78704;
  background-color: #0000;
}

.button.is-text[disabled], fieldset[disabled] .button.is-text {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
}

.button.is-white {
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white:hover, .button.is-white.is-hovered {
  color: #000;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-white:focus, .button.is-white.is-focused {
  color: #000;
  border-color: #0000;
}

.button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
  box-shadow: 0 #ffffff40;
}

.button.is-white:active, .button.is-white.is-active {
  color: #000;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-white[disabled], fieldset[disabled] .button.is-white {
  box-shadow: none;
  background-color: #fff;
  border-color: #0000;
}

.button.is-white.is-inverted {
  color: #fff;
  background-color: #000;
}

.button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
  background-color: #000;
}

.button.is-white.is-inverted[disabled], fieldset[disabled] .button.is-white.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-white.is-loading:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-white.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.button.is-white.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-outlined.is-loading:hover:after, .button.is-white.is-outlined.is-loading.is-hovered:after, .button.is-white.is-outlined.is-loading:focus:after, .button.is-white.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-white.is-outlined[disabled], fieldset[disabled] .button.is-white.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-white.is-inverted.is-outlined {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #000;
}

.button.is-white.is-inverted.is-outlined.is-loading:hover:after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-white.is-inverted.is-outlined.is-loading:focus:after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-white.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-white.is-inverted.is-outlined {
  box-shadow: none;
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black, .button.is-black:hover, .button.is-black.is-hovered {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black:focus, .button.is-black.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
  box-shadow: 0 #00000040;
}

.button.is-black:active, .button.is-black.is-active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-black[disabled], fieldset[disabled] .button.is-black {
  box-shadow: none;
  background-color: #000;
  border-color: #0000;
}

.button.is-black.is-inverted {
  color: #000;
  background-color: #fff;
}

.button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-black.is-inverted[disabled], fieldset[disabled] .button.is-black.is-inverted {
  box-shadow: none;
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-black.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.button.is-black.is-outlined.is-loading:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-black.is-outlined.is-loading:hover:after, .button.is-black.is-outlined.is-loading.is-hovered:after, .button.is-black.is-outlined.is-loading:focus:after, .button.is-black.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-black.is-outlined[disabled], fieldset[disabled] .button.is-black.is-outlined {
  box-shadow: none;
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-black.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
  color: #000;
  background-color: #fff;
}

.button.is-black.is-inverted.is-outlined.is-loading:hover:after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-black.is-inverted.is-outlined.is-loading:focus:after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-black.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-black.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light {
  color: #000000b3;
  background-color: #fff;
  border-color: #0000;
}

.button.is-light:hover, .button.is-light.is-hovered {
  color: #000000b3;
  background-color: #f9f9f9;
  border-color: #0000;
}

.button.is-light:focus, .button.is-light.is-focused {
  color: #000000b3;
  border-color: #0000;
}

.button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
  box-shadow: 0 #ffffff40;
}

.button.is-light:active, .button.is-light.is-active {
  color: #000000b3;
  background-color: #f2f2f2;
  border-color: #0000;
}

.button.is-light[disabled], fieldset[disabled] .button.is-light {
  box-shadow: none;
  background-color: #fff;
  border-color: #0000;
}

.button.is-light.is-inverted {
  color: #fff;
  background-color: #000000b3;
}

.button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
  background-color: #000000b3;
}

.button.is-light.is-inverted[disabled], fieldset[disabled] .button.is-light.is-inverted {
  box-shadow: none;
  color: #fff;
  background-color: #000000b3;
  border-color: #0000;
}

.button.is-light.is-loading:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
  color: #000000b3;
  background-color: #fff;
  border-color: #fff;
}

.button.is-light.is-outlined.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-light.is-outlined.is-loading:hover:after, .button.is-light.is-outlined.is-loading.is-hovered:after, .button.is-light.is-outlined.is-loading:focus:after, .button.is-light.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000000b3 #000000b3 !important;
}

.button.is-light.is-outlined[disabled], fieldset[disabled] .button.is-light.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-light.is-inverted.is-outlined {
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
  color: #fff;
  background-color: #000000b3;
}

.button.is-light.is-inverted.is-outlined.is-loading:hover:after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-light.is-inverted.is-outlined.is-loading:focus:after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-light.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-light.is-inverted.is-outlined {
  box-shadow: none;
  color: #000000b3;
  background-color: #0000;
  border-color: #000000b3;
}

.button.is-dark, .button.is-dark:hover, .button.is-dark.is-hovered {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-dark:focus, .button.is-dark.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
  box-shadow: 0 #00000040;
}

.button.is-dark:active, .button.is-dark.is-active {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.button.is-dark[disabled], fieldset[disabled] .button.is-dark {
  box-shadow: none;
  background-color: #000;
  border-color: #0000;
}

.button.is-dark.is-inverted {
  color: #000;
  background-color: #fff;
}

.button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-dark.is-inverted[disabled], fieldset[disabled] .button.is-dark.is-inverted {
  box-shadow: none;
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-dark.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.button.is-dark.is-outlined.is-loading:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-dark.is-outlined.is-loading:hover:after, .button.is-dark.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-outlined.is-loading:focus:after, .button.is-dark.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-dark.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-outlined {
  box-shadow: none;
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.button.is-dark.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
  color: #000;
  background-color: #fff;
}

.button.is-dark.is-inverted.is-outlined.is-loading:hover:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-dark.is-inverted.is-outlined.is-loading:focus:after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #000 #000 !important;
}

.button.is-dark.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary {
  color: #fff;
  background-color: #e78704;
  border-color: #0000;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
  color: #fff;
  background-color: #da8004;
  border-color: #0000;
}

.button.is-primary:focus, .button.is-primary.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 #e7870440;
}

.button.is-primary:active, .button.is-primary.is-active {
  color: #fff;
  background-color: #ce7804;
  border-color: #0000;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
  box-shadow: none;
  background-color: #e78704;
  border-color: #0000;
}

.button.is-primary.is-inverted {
  color: #e78704;
  background-color: #fff;
}

.button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-primary.is-inverted[disabled], fieldset[disabled] .button.is-primary.is-inverted {
  box-shadow: none;
  color: #e78704;
  background-color: #fff;
  border-color: #0000;
}

.button.is-primary.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined {
  color: #e78704;
  background-color: #0000;
  border-color: #e78704;
}

.button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
  color: #fff;
  background-color: #e78704;
  border-color: #e78704;
}

.button.is-primary.is-outlined.is-loading:after {
  border-color: #0000 #0000 #e78704 #e78704 !important;
}

.button.is-primary.is-outlined.is-loading:hover:after, .button.is-primary.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-outlined.is-loading:focus:after, .button.is-primary.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-primary.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-outlined {
  box-shadow: none;
  color: #e78704;
  background-color: #0000;
  border-color: #e78704;
}

.button.is-primary.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
  color: #e78704;
  background-color: #fff;
}

.button.is-primary.is-inverted.is-outlined.is-loading:hover:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-primary.is-inverted.is-outlined.is-loading:focus:after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #e78704 #e78704 !important;
}

.button.is-primary.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-primary.is-light {
  color: #b46903;
  background-color: #fff6eb;
}

.button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
  color: #b46903;
  background-color: #fef1de;
  border-color: #0000;
}

.button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
  color: #b46903;
  background-color: #feebd2;
  border-color: #0000;
}

.button.is-link {
  color: #fff;
  background-color: #e78704;
  border-color: #0000;
}

.button.is-link:hover, .button.is-link.is-hovered {
  color: #fff;
  background-color: #da8004;
  border-color: #0000;
}

.button.is-link:focus, .button.is-link.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
  box-shadow: 0 #e7870440;
}

.button.is-link:active, .button.is-link.is-active {
  color: #fff;
  background-color: #ce7804;
  border-color: #0000;
}

.button.is-link[disabled], fieldset[disabled] .button.is-link {
  box-shadow: none;
  background-color: #e78704;
  border-color: #0000;
}

.button.is-link.is-inverted {
  color: #e78704;
  background-color: #fff;
}

.button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-link.is-inverted[disabled], fieldset[disabled] .button.is-link.is-inverted {
  box-shadow: none;
  color: #e78704;
  background-color: #fff;
  border-color: #0000;
}

.button.is-link.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined {
  color: #e78704;
  background-color: #0000;
  border-color: #e78704;
}

.button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
  color: #fff;
  background-color: #e78704;
  border-color: #e78704;
}

.button.is-link.is-outlined.is-loading:after {
  border-color: #0000 #0000 #e78704 #e78704 !important;
}

.button.is-link.is-outlined.is-loading:hover:after, .button.is-link.is-outlined.is-loading.is-hovered:after, .button.is-link.is-outlined.is-loading:focus:after, .button.is-link.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-link.is-outlined[disabled], fieldset[disabled] .button.is-link.is-outlined {
  box-shadow: none;
  color: #e78704;
  background-color: #0000;
  border-color: #e78704;
}

.button.is-link.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
  color: #e78704;
  background-color: #fff;
}

.button.is-link.is-inverted.is-outlined.is-loading:hover:after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-link.is-inverted.is-outlined.is-loading:focus:after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #e78704 #e78704 !important;
}

.button.is-link.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-link.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-link.is-light {
  color: #b46903;
  background-color: #fff6eb;
}

.button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
  color: #b46903;
  background-color: #fef1de;
  border-color: #0000;
}

.button.is-link.is-light:active, .button.is-link.is-light.is-active {
  color: #b46903;
  background-color: #feebd2;
  border-color: #0000;
}

.button.is-info {
  color: #fff;
  background-color: #00b3cc;
  border-color: #0000;
}

.button.is-info:hover, .button.is-info.is-hovered {
  color: #fff;
  background-color: #00a8bf;
  border-color: #0000;
}

.button.is-info:focus, .button.is-info.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
  box-shadow: 0 #00b3cc40;
}

.button.is-info:active, .button.is-info.is-active {
  color: #fff;
  background-color: #009db3;
  border-color: #0000;
}

.button.is-info[disabled], fieldset[disabled] .button.is-info {
  box-shadow: none;
  background-color: #00b3cc;
  border-color: #0000;
}

.button.is-info.is-inverted {
  color: #00b3cc;
  background-color: #fff;
}

.button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-info.is-inverted[disabled], fieldset[disabled] .button.is-info.is-inverted {
  box-shadow: none;
  color: #00b3cc;
  background-color: #fff;
  border-color: #0000;
}

.button.is-info.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined {
  color: #00b3cc;
  background-color: #0000;
  border-color: #00b3cc;
}

.button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
  color: #fff;
  background-color: #00b3cc;
  border-color: #00b3cc;
}

.button.is-info.is-outlined.is-loading:after {
  border-color: #0000 #0000 #00b3cc #00b3cc !important;
}

.button.is-info.is-outlined.is-loading:hover:after, .button.is-info.is-outlined.is-loading.is-hovered:after, .button.is-info.is-outlined.is-loading:focus:after, .button.is-info.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-info.is-outlined[disabled], fieldset[disabled] .button.is-info.is-outlined {
  box-shadow: none;
  color: #00b3cc;
  background-color: #0000;
  border-color: #00b3cc;
}

.button.is-info.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
  color: #00b3cc;
  background-color: #fff;
}

.button.is-info.is-inverted.is-outlined.is-loading:hover:after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-info.is-inverted.is-outlined.is-loading:focus:after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #00b3cc #00b3cc !important;
}

.button.is-info.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-info.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-info.is-light {
  color: #009db3;
  background-color: #ebfdff;
}

.button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
  color: #009db3;
  background-color: #defbff;
  border-color: #0000;
}

.button.is-info.is-light:active, .button.is-info.is-light.is-active {
  color: #009db3;
  background-color: #d1f9ff;
  border-color: #0000;
}

.button.is-success {
  color: #fff;
  background-color: #5bc72b;
  border-color: #0000;
}

.button.is-success:hover, .button.is-success.is-hovered {
  color: #fff;
  background-color: #56bd29;
  border-color: #0000;
}

.button.is-success:focus, .button.is-success.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
  box-shadow: 0 #5bc72b40;
}

.button.is-success:active, .button.is-success.is-active {
  color: #fff;
  background-color: #51b226;
  border-color: #0000;
}

.button.is-success[disabled], fieldset[disabled] .button.is-success {
  box-shadow: none;
  background-color: #5bc72b;
  border-color: #0000;
}

.button.is-success.is-inverted {
  color: #5bc72b;
  background-color: #fff;
}

.button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-success.is-inverted[disabled], fieldset[disabled] .button.is-success.is-inverted {
  box-shadow: none;
  color: #5bc72b;
  background-color: #fff;
  border-color: #0000;
}

.button.is-success.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined {
  color: #5bc72b;
  background-color: #0000;
  border-color: #5bc72b;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  color: #fff;
  background-color: #5bc72b;
  border-color: #5bc72b;
}

.button.is-success.is-outlined.is-loading:after {
  border-color: #0000 #0000 #5bc72b #5bc72b !important;
}

.button.is-success.is-outlined.is-loading:hover:after, .button.is-success.is-outlined.is-loading.is-hovered:after, .button.is-success.is-outlined.is-loading:focus:after, .button.is-success.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-success.is-outlined[disabled], fieldset[disabled] .button.is-success.is-outlined {
  box-shadow: none;
  color: #5bc72b;
  background-color: #0000;
  border-color: #5bc72b;
}

.button.is-success.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
  color: #5bc72b;
  background-color: #fff;
}

.button.is-success.is-inverted.is-outlined.is-loading:hover:after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-success.is-inverted.is-outlined.is-loading:focus:after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #5bc72b #5bc72b !important;
}

.button.is-success.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-success.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-success.is-light {
  color: #3b821c;
  background-color: #f2fbee;
}

.button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
  color: #3b821c;
  background-color: #eaf9e4;
  border-color: #0000;
}

.button.is-success.is-light:active, .button.is-success.is-light.is-active {
  color: #3b821c;
  background-color: #e2f7d9;
  border-color: #0000;
}

.button.is-warning {
  color: #fff;
  background-color: #d3007e;
  border-color: #0000;
}

.button.is-warning:hover, .button.is-warning.is-hovered {
  color: #fff;
  background-color: #c60076;
  border-color: #0000;
}

.button.is-warning:focus, .button.is-warning.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
  box-shadow: 0 #d3007e40;
}

.button.is-warning:active, .button.is-warning.is-active {
  color: #fff;
  background-color: #ba006f;
  border-color: #0000;
}

.button.is-warning[disabled], fieldset[disabled] .button.is-warning {
  box-shadow: none;
  background-color: #d3007e;
  border-color: #0000;
}

.button.is-warning.is-inverted {
  color: #d3007e;
  background-color: #fff;
}

.button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-warning.is-inverted[disabled], fieldset[disabled] .button.is-warning.is-inverted {
  box-shadow: none;
  color: #d3007e;
  background-color: #fff;
  border-color: #0000;
}

.button.is-warning.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-warning.is-outlined {
  color: #d3007e;
  background-color: #0000;
  border-color: #d3007e;
}

.button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
  color: #fff;
  background-color: #d3007e;
  border-color: #d3007e;
}

.button.is-warning.is-outlined.is-loading:after {
  border-color: #0000 #0000 #d3007e #d3007e !important;
}

.button.is-warning.is-outlined.is-loading:hover:after, .button.is-warning.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-outlined.is-loading:focus:after, .button.is-warning.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-warning.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-outlined {
  box-shadow: none;
  color: #d3007e;
  background-color: #0000;
  border-color: #d3007e;
}

.button.is-warning.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
  color: #d3007e;
  background-color: #fff;
}

.button.is-warning.is-inverted.is-outlined.is-loading:hover:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-warning.is-inverted.is-outlined.is-loading:focus:after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #d3007e #d3007e !important;
}

.button.is-warning.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-warning.is-light {
  color: #f50092;
  background-color: #ffebf7;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
  color: #f50092;
  background-color: #ffdef2;
  border-color: #0000;
}

.button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
  color: #f50092;
  background-color: #ffd1ed;
  border-color: #0000;
}

.button.is-danger {
  color: #fff;
  background-color: #d30000;
  border-color: #0000;
}

.button.is-danger:hover, .button.is-danger.is-hovered {
  color: #fff;
  background-color: #c60000;
  border-color: #0000;
}

.button.is-danger:focus, .button.is-danger.is-focused {
  color: #fff;
  border-color: #0000;
}

.button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
  box-shadow: 0 #d3000040;
}

.button.is-danger:active, .button.is-danger.is-active {
  color: #fff;
  background-color: #ba0000;
  border-color: #0000;
}

.button.is-danger[disabled], fieldset[disabled] .button.is-danger {
  box-shadow: none;
  background-color: #d30000;
  border-color: #0000;
}

.button.is-danger.is-inverted {
  color: #d30000;
  background-color: #fff;
}

.button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
  background-color: #f2f2f2;
}

.button.is-danger.is-inverted[disabled], fieldset[disabled] .button.is-danger.is-inverted {
  box-shadow: none;
  color: #d30000;
  background-color: #fff;
  border-color: #0000;
}

.button.is-danger.is-loading:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined {
  color: #d30000;
  background-color: #0000;
  border-color: #d30000;
}

.button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
  color: #fff;
  background-color: #d30000;
  border-color: #d30000;
}

.button.is-danger.is-outlined.is-loading:after {
  border-color: #0000 #0000 #d30000 #d30000 !important;
}

.button.is-danger.is-outlined.is-loading:hover:after, .button.is-danger.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-outlined.is-loading:focus:after, .button.is-danger.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #fff #fff !important;
}

.button.is-danger.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-outlined {
  box-shadow: none;
  color: #d30000;
  background-color: #0000;
  border-color: #d30000;
}

.button.is-danger.is-inverted.is-outlined {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
  color: #d30000;
  background-color: #fff;
}

.button.is-danger.is-inverted.is-outlined.is-loading:hover:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered:after, .button.is-danger.is-inverted.is-outlined.is-loading:focus:after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused:after {
  border-color: #0000 #0000 #d30000 #d30000 !important;
}

.button.is-danger.is-inverted.is-outlined[disabled], fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border-color: #fff;
}

.button.is-danger.is-light {
  color: #fa0000;
  background-color: #ffebeb;
}

.button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
  color: #fa0000;
  background-color: #ffdede;
  border-color: #0000;
}

.button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
  color: #fa0000;
  background-color: #ffd1d1;
  border-color: #0000;
}

.button.is-small {
  border-radius: 5px;
  font-size: 1.2rem;
}

.button.is-normal {
  font-size: 1.6rem;
}

.button.is-medium {
  font-size: 2.4rem;
}

.button.is-large {
  font-size: 3.2rem;
}

.button[disabled], fieldset[disabled] .button {
  box-shadow: none;
  opacity: .5;
  background-color: #e6e6e6;
  border-color: #0000;
}

.button.is-fullwidth {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  position: absolute !important;
}

.button.is-static {
  color: #7f7f7f;
  box-shadow: none;
  pointer-events: none;
  background-color: #f2f2f2;
  border-color: #e6e6e6;
}

.button.is-rounded {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons .button {
  margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: .5rem;
}

.buttons:last-child {
  margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
  margin-bottom: 1rem;
}

.buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
  border-radius: 5px;
  font-size: 1.2rem;
}

.buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
  font-size: 2.4rem;
}

.buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
  font-size: 3.2rem;
}

.buttons.has-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.buttons.has-addons .button:last-child {
  margin-right: 0;
}

.buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
  z-index: 2;
}

.buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
  z-index: 3;
}

.buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.has-addons .button.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.buttons.is-centered {
  justify-content: center;
}

.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.buttons.is-right {
  justify-content: flex-end;
}

.buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
  margin-left: .25rem;
  margin-right: .25rem;
}

.container {
  flex-grow: 1;
  width: auto;
  margin: 0 auto;
  position: relative;
}

.container.is-fluid {
  width: 100%;
  max-width: none;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (width >= 980px) {
  .container {
    max-width: 980px;
  }
}

@media screen and (width <= 1171px) {
  .container.is-widescreen {
    max-width: 1172px;
  }
}

@media screen and (width <= 1363px) {
  .container.is-fullhd {
    max-width: 1364px;
  }
}

@media screen and (width >= 1172px) {
  .container {
    max-width: 1172px;
  }
}

@media screen and (width >= 1364px) {
  .container {
    max-width: 1364px;
  }
}

.icon {
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  display: inline-flex;
}

.icon.is-small {
  width: 1.6rem;
  height: 1.6rem;
}

.icon.is-medium, .icon.is-large {
  width: 3.2rem;
  height: 3.2rem;
}

.notification {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
}

.notification a:not(.button):not(.dropdown-item) {
  color: currentColor;
  text-decoration: underline;
}

.notification strong {
  color: currentColor;
}

.notification code, .notification pre {
  background: #fff;
}

.notification pre code {
  background: none;
}

.notification > .delete {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.notification .title, .notification .subtitle, .notification .content {
  color: currentColor;
}

.notification.is-white {
  color: #000;
  background-color: #fff;
}

.notification.is-black {
  color: #fff;
  background-color: #000;
}

.notification.is-light {
  color: #000000b3;
  background-color: #fff;
}

.notification.is-dark {
  color: #fff;
  background-color: #000;
}

.notification.is-primary {
  color: #fff;
  background-color: #e78704;
}

.notification.is-primary.is-light {
  color: #b46903;
  background-color: #fff6eb;
}

.notification.is-link {
  color: #fff;
  background-color: #e78704;
}

.notification.is-link.is-light {
  color: #b46903;
  background-color: #fff6eb;
}

.notification.is-info {
  color: #fff;
  background-color: #00b3cc;
}

.notification.is-info.is-light {
  color: #009db3;
  background-color: #ebfdff;
}

.notification.is-success {
  color: #fff;
  background-color: #5bc72b;
}

.notification.is-success.is-light {
  color: #3b821c;
  background-color: #f2fbee;
}

.notification.is-warning {
  color: #fff;
  background-color: #d3007e;
}

.notification.is-warning.is-light {
  color: #f50092;
  background-color: #ffebf7;
}

.notification.is-danger {
  color: #fff;
  background-color: #d30000;
}

.notification.is-danger.is-light {
  color: #fa0000;
  background-color: #ffebeb;
}

.select select, .textarea, .input {
  color: #000;
  background-color: #f2f2f2;
  border-color: #bfbfbf;
  border-radius: 20px;
}

.select select::-moz-placeholder {
  color: #7f7f7f;
}

.textarea::-moz-placeholder {
  color: #7f7f7f;
}

.input::-moz-placeholder {
  color: #7f7f7f;
}

.select select::-webkit-input-placeholder {
  color: #7f7f7f;
}

.textarea::-webkit-input-placeholder {
  color: #7f7f7f;
}

.input::-webkit-input-placeholder {
  color: #7f7f7f;
}

.select select:-moz-placeholder {
  color: #7f7f7f;
}

.textarea:-moz-placeholder {
  color: #7f7f7f;
}

.input:-moz-placeholder {
  color: #7f7f7f;
}

.select select:-ms-input-placeholder {
  color: #7f7f7f;
}

.textarea:-ms-input-placeholder {
  color: #7f7f7f;
}

.input:-ms-input-placeholder {
  color: #7f7f7f;
}

.select select:hover, .textarea:hover, .input:hover, .select select.is-hovered, .is-hovered.textarea, .is-hovered.input {
  border-color: #7f7f7f;
}

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input {
  box-shadow: 0 none;
  border-color: #000;
}

.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  box-shadow: none;
  color: #7f7f7f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.select select[disabled]::-moz-placeholder {
  color: r #bfbfbf;
}

[disabled].textarea::-moz-placeholder {
  color: r #bfbfbf;
}

[disabled].input::-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .select select::-moz-placeholder {
  color: r #bfbfbf;
}

.select fieldset[disabled] select::-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .textarea::-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .input::-moz-placeholder {
  color: r #bfbfbf;
}

.select select[disabled]::-webkit-input-placeholder {
  color: r #bfbfbf;
}

[disabled].textarea::-webkit-input-placeholder {
  color: r #bfbfbf;
}

[disabled].input::-webkit-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .select select::-webkit-input-placeholder {
  color: r #bfbfbf;
}

.select fieldset[disabled] select::-webkit-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .textarea::-webkit-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .input::-webkit-input-placeholder {
  color: r #bfbfbf;
}

.select select[disabled]:-moz-placeholder {
  color: r #bfbfbf;
}

[disabled].textarea:-moz-placeholder {
  color: r #bfbfbf;
}

[disabled].input:-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .select select:-moz-placeholder {
  color: r #bfbfbf;
}

.select fieldset[disabled] select:-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .textarea:-moz-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .input:-moz-placeholder {
  color: r #bfbfbf;
}

.select select[disabled]:-ms-input-placeholder {
  color: r #bfbfbf;
}

[disabled].textarea:-ms-input-placeholder {
  color: r #bfbfbf;
}

[disabled].input:-ms-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .select select:-ms-input-placeholder {
  color: r #bfbfbf;
}

.select fieldset[disabled] select:-ms-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .textarea:-ms-input-placeholder {
  color: r #bfbfbf;
}

fieldset[disabled] .input:-ms-input-placeholder {
  color: r #bfbfbf;
}

.textarea, .input {
  box-shadow: none;
  width: 100%;
  max-width: 100%;
}

[readonly].textarea, [readonly].input {
  box-shadow: none;
}

.is-white.textarea, .is-white.input {
  border-color: #fff;
}

.is-white.textarea:focus, .is-white.input:focus, .is-white.is-focused.textarea, .is-white.is-focused.input, .is-white.textarea:active, .is-white.input:active, .is-white.is-active.textarea, .is-white.is-active.input {
  box-shadow: 0 #ffffff40;
}

.is-black.textarea, .is-black.input {
  border-color: #000;
}

.is-black.textarea:focus, .is-black.input:focus, .is-black.is-focused.textarea, .is-black.is-focused.input, .is-black.textarea:active, .is-black.input:active, .is-black.is-active.textarea, .is-black.is-active.input {
  box-shadow: 0 #00000040;
}

.is-light.textarea, .is-light.input {
  border-color: #fff;
}

.is-light.textarea:focus, .is-light.input:focus, .is-light.is-focused.textarea, .is-light.is-focused.input, .is-light.textarea:active, .is-light.input:active, .is-light.is-active.textarea, .is-light.is-active.input {
  box-shadow: 0 #ffffff40;
}

.is-dark.textarea, .is-dark.input {
  border-color: #000;
}

.is-dark.textarea:focus, .is-dark.input:focus, .is-dark.is-focused.textarea, .is-dark.is-focused.input, .is-dark.textarea:active, .is-dark.input:active, .is-dark.is-active.textarea, .is-dark.is-active.input {
  box-shadow: 0 #00000040;
}

.is-primary.textarea, .is-primary.input {
  border-color: #e78704;
}

.is-primary.textarea:focus, .is-primary.input:focus, .is-primary.is-focused.textarea, .is-primary.is-focused.input, .is-primary.textarea:active, .is-primary.input:active, .is-primary.is-active.textarea, .is-primary.is-active.input {
  box-shadow: 0 #e7870440;
}

.is-link.textarea, .is-link.input {
  border-color: #e78704;
}

.is-link.textarea:focus, .is-link.input:focus, .is-link.is-focused.textarea, .is-link.is-focused.input, .is-link.textarea:active, .is-link.input:active, .is-link.is-active.textarea, .is-link.is-active.input {
  box-shadow: 0 #e7870440;
}

.is-info.textarea, .is-info.input {
  border-color: #00b3cc;
}

.is-info.textarea:focus, .is-info.input:focus, .is-info.is-focused.textarea, .is-info.is-focused.input, .is-info.textarea:active, .is-info.input:active, .is-info.is-active.textarea, .is-info.is-active.input {
  box-shadow: 0 #00b3cc40;
}

.is-success.textarea, .is-success.input {
  border-color: #5bc72b;
}

.is-success.textarea:focus, .is-success.input:focus, .is-success.is-focused.textarea, .is-success.is-focused.input, .is-success.textarea:active, .is-success.input:active, .is-success.is-active.textarea, .is-success.is-active.input {
  box-shadow: 0 #5bc72b40;
}

.is-warning.textarea, .is-warning.input {
  border-color: #d3007e;
}

.is-warning.textarea:focus, .is-warning.input:focus, .is-warning.is-focused.textarea, .is-warning.is-focused.input, .is-warning.textarea:active, .is-warning.input:active, .is-warning.is-active.textarea, .is-warning.is-active.input {
  box-shadow: 0 #d3007e40;
}

.is-danger.textarea, .is-danger.input {
  border-color: #d30000;
}

.is-danger.textarea:focus, .is-danger.input:focus, .is-danger.is-focused.textarea, .is-danger.is-focused.input, .is-danger.textarea:active, .is-danger.input:active, .is-danger.is-active.textarea, .is-danger.is-active.input {
  box-shadow: 0 #d3000040;
}

.is-small.textarea, .is-small.input {
  border-radius: 5px;
  font-size: 1.2rem;
}

.is-medium.textarea, .is-medium.input {
  font-size: 2.4rem;
}

.is-large.textarea, .is-large.input {
  font-size: 3.2rem;
}

.is-fullwidth.textarea, .is-fullwidth.input {
  width: 100%;
  display: block;
}

.is-inline.textarea, .is-inline.input {
  width: auto;
  display: inline;
}

.input.is-rounded {
  border-radius: 290486px;
  padding-left: calc(1.125em - 2px);
  padding-right: calc(1.125em - 2px);
}

.input.is-static {
  box-shadow: none;
  background-color: #0000;
  border-color: #0000;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  resize: vertical;
  min-width: 100%;
  max-width: 100%;
  padding: calc(.75em - 2px);
  display: block;
}

.textarea:not([rows]) {
  min-height: 8em;
  max-height: 40em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

.radio, .checkbox {
  cursor: pointer;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.radio input, .checkbox input {
  cursor: pointer;
}

.radio:hover, .checkbox:hover {
  color: #000;
}

[disabled].radio, [disabled].checkbox, fieldset[disabled] .radio, fieldset[disabled] .checkbox {
  color: #7f7f7f;
  cursor: not-allowed;
}

.select {
  vertical-align: top;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.select:not(.is-multiple) {
  height: 2.25em;
}

.select:not(.is-multiple):not(.is-loading):after {
  z-index: 4;
  border-color: #e78704;
  right: 1.125em;
}

.select.is-rounded select {
  border-radius: 290486px;
  padding-left: 1em;
}

.select select {
  cursor: pointer;
  outline: none;
  max-width: 100%;
  font-size: 1em;
  display: block;
}

.select select::-ms-expand {
  display: none;
}

.select select[disabled]:hover, fieldset[disabled] .select select:hover {
  border-color: #f2f2f2;
}

.select select:not([multiple]) {
  padding-right: 2.5em;
}

.select select[multiple] {
  height: auto;
  padding: 0;
}

.select select[multiple] option {
  padding: .5em 1em;
}

.select:not(.is-multiple):not(.is-loading):hover:after {
  border-color: #000;
}

.select.is-white:not(:hover):after, .select.is-white select {
  border-color: #fff;
}

.select.is-white select:hover, .select.is-white select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
  box-shadow: 0 #ffffff40;
}

.select.is-black:not(:hover):after, .select.is-black select, .select.is-black select:hover, .select.is-black select.is-hovered {
  border-color: #000;
}

.select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
  box-shadow: 0 #00000040;
}

.select.is-light:not(:hover):after, .select.is-light select {
  border-color: #fff;
}

.select.is-light select:hover, .select.is-light select.is-hovered {
  border-color: #f2f2f2;
}

.select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
  box-shadow: 0 #ffffff40;
}

.select.is-dark:not(:hover):after, .select.is-dark select, .select.is-dark select:hover, .select.is-dark select.is-hovered {
  border-color: #000;
}

.select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
  box-shadow: 0 #00000040;
}

.select.is-primary:not(:hover):after, .select.is-primary select {
  border-color: #e78704;
}

.select.is-primary select:hover, .select.is-primary select.is-hovered {
  border-color: #ce7804;
}

.select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
  box-shadow: 0 #e7870440;
}

.select.is-link:not(:hover):after, .select.is-link select {
  border-color: #e78704;
}

.select.is-link select:hover, .select.is-link select.is-hovered {
  border-color: #ce7804;
}

.select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
  box-shadow: 0 #e7870440;
}

.select.is-info:not(:hover):after, .select.is-info select {
  border-color: #00b3cc;
}

.select.is-info select:hover, .select.is-info select.is-hovered {
  border-color: #009db3;
}

.select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
  box-shadow: 0 #00b3cc40;
}

.select.is-success:not(:hover):after, .select.is-success select {
  border-color: #5bc72b;
}

.select.is-success select:hover, .select.is-success select.is-hovered {
  border-color: #51b226;
}

.select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
  box-shadow: 0 #5bc72b40;
}

.select.is-warning:not(:hover):after, .select.is-warning select {
  border-color: #d3007e;
}

.select.is-warning select:hover, .select.is-warning select.is-hovered {
  border-color: #ba006f;
}

.select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
  box-shadow: 0 #d3007e40;
}

.select.is-danger:not(:hover):after, .select.is-danger select {
  border-color: #d30000;
}

.select.is-danger select:hover, .select.is-danger select.is-hovered {
  border-color: #ba0000;
}

.select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
  box-shadow: 0 #d3000040;
}

.select.is-small {
  border-radius: 5px;
  font-size: 1.2rem;
}

.select.is-medium {
  font-size: 2.4rem;
}

.select.is-large {
  font-size: 3.2rem;
}

.select.is-disabled:after {
  border-color: #7f7f7f;
}

.select.is-fullwidth, .select.is-fullwidth select {
  width: 100%;
}

.select.is-loading:after {
  margin-top: 0;
  position: absolute;
  top: .625em;
  right: .625em;
  transform: none;
}

.select.is-loading.is-small:after {
  font-size: 1.2rem;
}

.select.is-loading.is-medium:after {
  font-size: 2.4rem;
}

.select.is-loading.is-large:after {
  font-size: 3.2rem;
}

.file {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
}

.file.is-white .file-cta {
  color: #000;
  background-color: #fff;
  border-color: #0000;
}

.file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
  color: #000;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
  color: #000;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
  color: #000;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-black .file-cta, .file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #00000040;
}

.file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-light .file-cta {
  color: #000000b3;
  background-color: #fff;
  border-color: #0000;
}

.file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
  color: #000000b3;
  background-color: #f9f9f9;
  border-color: #0000;
}

.file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
  color: #000000b3;
  border-color: #0000;
  box-shadow: 0 0 .5em #ffffff40;
}

.file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
  color: #000000b3;
  background-color: #f2f2f2;
  border-color: #0000;
}

.file.is-dark .file-cta, .file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #00000040;
}

.file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
  color: #fff;
  background-color: #000;
  border-color: #0000;
}

.file.is-primary .file-cta {
  color: #fff;
  background-color: #e78704;
  border-color: #0000;
}

.file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
  color: #fff;
  background-color: #da8004;
  border-color: #0000;
}

.file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #e7870440;
}

.file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
  color: #fff;
  background-color: #ce7804;
  border-color: #0000;
}

.file.is-link .file-cta {
  color: #fff;
  background-color: #e78704;
  border-color: #0000;
}

.file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
  color: #fff;
  background-color: #da8004;
  border-color: #0000;
}

.file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #e7870440;
}

.file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
  color: #fff;
  background-color: #ce7804;
  border-color: #0000;
}

.file.is-info .file-cta {
  color: #fff;
  background-color: #00b3cc;
  border-color: #0000;
}

.file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
  color: #fff;
  background-color: #00a8bf;
  border-color: #0000;
}

.file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #00b3cc40;
}

.file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
  color: #fff;
  background-color: #009db3;
  border-color: #0000;
}

.file.is-success .file-cta {
  color: #fff;
  background-color: #5bc72b;
  border-color: #0000;
}

.file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
  color: #fff;
  background-color: #56bd29;
  border-color: #0000;
}

.file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #5bc72b40;
}

.file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
  color: #fff;
  background-color: #51b226;
  border-color: #0000;
}

.file.is-warning .file-cta {
  color: #fff;
  background-color: #d3007e;
  border-color: #0000;
}

.file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
  color: #fff;
  background-color: #c60076;
  border-color: #0000;
}

.file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #d3007e40;
}

.file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
  color: #fff;
  background-color: #ba006f;
  border-color: #0000;
}

.file.is-danger .file-cta {
  color: #fff;
  background-color: #d30000;
  border-color: #0000;
}

.file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
  color: #fff;
  background-color: #c60000;
  border-color: #0000;
}

.file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
  color: #fff;
  border-color: #0000;
  box-shadow: 0 0 .5em #d3000040;
}

.file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
  color: #fff;
  background-color: #ba0000;
  border-color: #0000;
}

.file.is-small {
  font-size: 1.2rem;
}

.file.is-medium {
  font-size: 2.4rem;
}

.file.is-medium .file-icon .fa {
  font-size: 21px;
}

.file.is-large {
  font-size: 3.2rem;
}

.file.is-large .file-icon .fa {
  font-size: 28px;
}

.file.has-name .file-cta {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file.has-name .file-name {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.file.has-name.is-empty .file-cta {
  border-radius: none;
}

.file.has-name.is-empty .file-name {
  display: none;
}

.file.is-boxed .file-label {
  flex-direction: column;
}

.file.is-boxed .file-cta {
  flex-direction: column;
  height: auto;
  padding: 1em 3em;
}

.file.is-boxed .file-name {
  border-width: 0 1px 1px;
}

.file.is-boxed .file-icon {
  width: 1.5em;
  height: 1.5em;
}

.file.is-boxed .file-icon .fa {
  font-size: 21px;
}

.file.is-boxed.is-small .file-icon .fa {
  font-size: 14px;
}

.file.is-boxed.is-medium .file-icon .fa {
  font-size: 28px;
}

.file.is-boxed.is-large .file-icon .fa {
  font-size: 35px;
}

.file.is-boxed.has-name .file-cta {
  border-radius: none none 0 0;
}

.file.is-boxed.has-name .file-name {
  border-radius: 0 0 none none;
  border-width: 0 1px 1px;
}

.file.is-centered {
  justify-content: center;
}

.file.is-fullwidth .file-label {
  width: 100%;
}

.file.is-fullwidth .file-name {
  flex-grow: 1;
  max-width: none;
}

.file.is-right {
  justify-content: flex-end;
}

.file.is-right .file-cta {
  border-radius: 0 none none 0;
}

.file.is-right .file-name {
  border-radius: none 0 0 none;
  border-width: 1px 0 1px 1px;
  order: -1;
}

.file-label {
  cursor: pointer;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow: hidden;
}

.file-label:hover .file-cta {
  color: #1a1a1a;
  background-color: #ececec;
}

.file-label:hover .file-name {
  border-color: #ececec;
}

.file-label:active .file-cta {
  color: #1a1a1a;
  background-color: #e5e5e5;
}

.file-label:active .file-name {
  border-color: #e5e5e5;
}

.file-input {
  opacity: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.file-cta, .file-name {
  border-radius: none;
  white-space: nowrap;
  border-color: #f2f2f2;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
}

.file-cta {
  color: #404040;
  background-color: #f2f2f2;
}

.file-name {
  text-align: left;
  text-overflow: ellipsis;
  border: 1px solid #f2f2f2;
  border-left-width: 0;
  max-width: 16em;
  display: block;
  overflow: hidden;
}

.file-icon {
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: flex;
}

.file-icon .fa {
  font-size: 14px;
}

.label {
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: .5em;
}

.label.is-small {
  font-size: 1.2rem;
}

.label.is-medium {
  font-size: 2.4rem;
}

.label.is-large {
  font-size: 3.2rem;
}

.help {
  margin-top: .25rem;
  font-size: 1.2rem;
  display: block;
}

.help.is-white {
  color: #fff;
}

.help.is-black {
  color: #000;
}

.help.is-light {
  color: #fff;
}

.help.is-dark {
  color: #000;
}

.help.is-primary, .help.is-link {
  color: #e78704;
}

.help.is-info {
  color: #00b3cc;
}

.help.is-success {
  color: #5bc72b;
}

.help.is-warning {
  color: #d3007e;
}

.help.is-danger {
  color: #d30000;
}

.field:not(:last-child) {
  margin-bottom: .75rem;
}

.field.has-addons {
  justify-content: flex-start;
  display: flex;
}

.field.has-addons .control:not(:last-child) {
  margin-right: -1px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button, .field.has-addons .control:not(:first-child):not(:last-child) .input, .field.has-addons .control:not(:first-child):not(:last-child) .select select {
  border-radius: 0;
}

.field.has-addons .control:first-child:not(:only-child) .button, .field.has-addons .control:first-child:not(:only-child) .input, .field.has-addons .control:first-child:not(:only-child) .select select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field.has-addons .control:last-child:not(:only-child) .button, .field.has-addons .control:last-child:not(:only-child) .input, .field.has-addons .control:last-child:not(:only-child) .select select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered, .field.has-addons .control .input:not([disabled]):hover, .field.has-addons .control .input:not([disabled]).is-hovered, .field.has-addons .control .select select:not([disabled]):hover, .field.has-addons .control .select select:not([disabled]).is-hovered {
  z-index: 2;
}

.field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active, .field.has-addons .control .input:not([disabled]):focus, .field.has-addons .control .input:not([disabled]).is-focused, .field.has-addons .control .input:not([disabled]):active, .field.has-addons .control .input:not([disabled]).is-active, .field.has-addons .control .select select:not([disabled]):focus, .field.has-addons .control .select select:not([disabled]).is-focused, .field.has-addons .control .select select:not([disabled]):active, .field.has-addons .control .select select:not([disabled]).is-active {
  z-index: 3;
}

.field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover, .field.has-addons .control .input:not([disabled]):focus:hover, .field.has-addons .control .input:not([disabled]).is-focused:hover, .field.has-addons .control .input:not([disabled]):active:hover, .field.has-addons .control .input:not([disabled]).is-active:hover, .field.has-addons .control .select select:not([disabled]):focus:hover, .field.has-addons .control .select select:not([disabled]).is-focused:hover, .field.has-addons .control .select select:not([disabled]):active:hover, .field.has-addons .control .select select:not([disabled]).is-active:hover {
  z-index: 4;
}

.field.has-addons .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.has-addons.has-addons-centered {
  justify-content: center;
}

.field.has-addons.has-addons-right {
  justify-content: flex-end;
}

.field.has-addons.has-addons-fullwidth .control {
  flex-grow: 1;
  flex-shrink: 0;
}

.field.is-grouped {
  justify-content: flex-start;
  display: flex;
}

.field.is-grouped > .control {
  flex-shrink: 0;
}

.field.is-grouped > .control:not(:last-child) {
  margin-bottom: 0;
  margin-right: .75rem;
}

.field.is-grouped > .control.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.field.is-grouped.is-grouped-centered {
  justify-content: center;
}

.field.is-grouped.is-grouped-right {
  justify-content: flex-end;
}

.field.is-grouped.is-grouped-multiline {
  flex-wrap: wrap;
}

.field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
  margin-bottom: .75rem;
}

.field.is-grouped.is-grouped-multiline:last-child {
  margin-bottom: -.75rem;
}

.field.is-grouped.is-grouped-multiline:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field.is-horizontal {
    display: flex;
  }
}

.field-label .label {
  font-size: inherit;
}

@media screen and (width <= 768px) {
  .field-label {
    margin-bottom: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .field-label {
    text-align: right;
    flex: 1 0 0;
    margin-right: 1.5rem;
  }

  .field-label.is-small {
    padding-top: .375em;
    font-size: 1.2rem;
  }

  .field-label.is-normal {
    padding-top: .375em;
  }

  .field-label.is-medium {
    padding-top: .375em;
    font-size: 2.4rem;
  }

  .field-label.is-large {
    padding-top: .375em;
    font-size: 3.2rem;
  }
}

.field-body .field .field {
  margin-bottom: 0;
}

@media screen and (width >= 769px), print {
  .field-body {
    flex: 5 1 0;
    display: flex;
  }

  .field-body .field {
    margin-bottom: 0;
  }

  .field-body > .field {
    flex-shrink: 1;
  }

  .field-body > .field:not(.is-narrow) {
    flex-grow: 1;
  }

  .field-body > .field:not(:last-child) {
    margin-right: .75rem;
  }
}

.control {
  box-sizing: border-box;
  clear: both;
  text-align: left;
  font-size: 1.6rem;
  position: relative;
}

.control.has-icons-left .input:focus ~ .icon, .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon, .control.has-icons-right .select:focus ~ .icon {
  color: #000;
}

.control.has-icons-left .input.is-small ~ .icon, .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon, .control.has-icons-right .select.is-small ~ .icon {
  font-size: 1.2rem;
}

.control.has-icons-left .input.is-medium ~ .icon, .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon, .control.has-icons-right .select.is-medium ~ .icon {
  font-size: 2.4rem;
}

.control.has-icons-left .input.is-large ~ .icon, .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon, .control.has-icons-right .select.is-large ~ .icon {
  font-size: 3.2rem;
}

.control.has-icons-left .icon, .control.has-icons-right .icon {
  color: #bfbfbf;
  pointer-events: none;
  z-index: 4;
  width: 2.25em;
  height: 2.25em;
  position: absolute;
  top: 0;
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 2.25em;
}

.control.has-icons-left .icon.is-left {
  left: 0;
}

.control.has-icons-right .input, .control.has-icons-right .select select {
  padding-right: 2.25em;
}

.control.has-icons-right .icon.is-right {
  right: 0;
}

.control.is-loading:after {
  z-index: 4;
  top: .625em;
  right: .625em;
  position: absolute !important;
}

.control.is-loading.is-small:after {
  font-size: 1.2rem;
}

.control.is-loading.is-medium:after {
  font-size: 2.4rem;
}

.control.is-loading.is-large:after {
  font-size: 3.2rem;
}

.modal {
  z-index: 40;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  overflow: hidden;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: #e6e6e6bf;
}

.modal-content, .modal-card {
  width: 100%;
  max-height: calc(100vh - 50px);
  margin: 0 50px;
  position: relative;
  overflow: auto;
}

@media screen and (width >= 769px), print {
  .modal-content, .modal-card {
    width: 769px;
    max-height: calc(100vh - 50px);
    margin: 0 auto;
  }
}

.modal-close {
  background: none;
  width: 10px;
  height: 10px;
  position: fixed;
  top: 5px;
  right: 5px;
}

.modal-card {
  -ms-overflow-y: visible;
  flex-direction: column;
  max-height: calc(100vh - 150px);
  display: flex;
  overflow: hidden;
}

.modal-card-head, .modal-card-foot {
  background-color: #fff;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 25px;
  display: flex;
  position: relative;
}

.modal-card-head {
  border-bottom: 2px solid #e6e6e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-card-title {
  color: #000;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.8rem;
  line-height: 1.5;
}

.modal-card-foot {
  border-top: 2px solid #e6e6e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: .5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px 25px;
  overflow: auto;
}

.navbar {
  z-index: 30;
  background-color: #0000;
  min-height: 50px;
  position: relative;
}

.navbar.is-white {
  color: #000;
  background-color: #fff;
}

.navbar.is-white .navbar-brand > .navbar-item, .navbar.is-white .navbar-brand .navbar-link {
  color: #000;
}

.navbar.is-white .navbar-brand > a.navbar-item:focus, .navbar.is-white .navbar-brand > a.navbar-item:hover, .navbar.is-white .navbar-brand > a.navbar-item.is-active, .navbar.is-white .navbar-brand .navbar-link:focus, .navbar.is-white .navbar-brand .navbar-link:hover, .navbar.is-white .navbar-brand .navbar-link.is-active {
  color: #000;
  background-color: #f2f2f2;
}

.navbar.is-white .navbar-brand .navbar-link:after {
  border-color: #000;
}

.navbar.is-white .navbar-burger {
  color: #000;
}

@media screen and (width >= 980px) {
  .navbar.is-white .navbar-start > .navbar-item, .navbar.is-white .navbar-start .navbar-link, .navbar.is-white .navbar-end > .navbar-item, .navbar.is-white .navbar-end .navbar-link {
    color: #000;
  }

  .navbar.is-white .navbar-start > a.navbar-item:focus, .navbar.is-white .navbar-start > a.navbar-item:hover, .navbar.is-white .navbar-start > a.navbar-item.is-active, .navbar.is-white .navbar-start .navbar-link:focus, .navbar.is-white .navbar-start .navbar-link:hover, .navbar.is-white .navbar-start .navbar-link.is-active, .navbar.is-white .navbar-end > a.navbar-item:focus, .navbar.is-white .navbar-end > a.navbar-item:hover, .navbar.is-white .navbar-end > a.navbar-item.is-active, .navbar.is-white .navbar-end .navbar-link:focus, .navbar.is-white .navbar-end .navbar-link:hover, .navbar.is-white .navbar-end .navbar-link.is-active {
    color: #000;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-start .navbar-link:after, .navbar.is-white .navbar-end .navbar-link:after {
    border-color: #000;
  }

  .navbar.is-white .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-white .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-white .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000;
    background-color: #f2f2f2;
  }

  .navbar.is-white .navbar-dropdown a.navbar-item.is-active {
    color: #000;
    background-color: #fff;
  }
}

.navbar.is-black {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand > .navbar-item, .navbar.is-black .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-black .navbar-brand > a.navbar-item:focus, .navbar.is-black .navbar-brand > a.navbar-item:hover, .navbar.is-black .navbar-brand > a.navbar-item.is-active, .navbar.is-black .navbar-brand .navbar-link:focus, .navbar.is-black .navbar-brand .navbar-link:hover, .navbar.is-black .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-black .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-black .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-black .navbar-start > .navbar-item, .navbar.is-black .navbar-start .navbar-link, .navbar.is-black .navbar-end > .navbar-item, .navbar.is-black .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-black .navbar-start > a.navbar-item:focus, .navbar.is-black .navbar-start > a.navbar-item:hover, .navbar.is-black .navbar-start > a.navbar-item.is-active, .navbar.is-black .navbar-start .navbar-link:focus, .navbar.is-black .navbar-start .navbar-link:hover, .navbar.is-black .navbar-start .navbar-link.is-active, .navbar.is-black .navbar-end > a.navbar-item:focus, .navbar.is-black .navbar-end > a.navbar-item:hover, .navbar.is-black .navbar-end > a.navbar-item.is-active, .navbar.is-black .navbar-end .navbar-link:focus, .navbar.is-black .navbar-end .navbar-link:hover, .navbar.is-black .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-black .navbar-start .navbar-link:after, .navbar.is-black .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-black .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-black .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-black .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #000;
  }
}

.navbar.is-light {
  color: #000000b3;
  background-color: #fff;
}

.navbar.is-light .navbar-brand > .navbar-item, .navbar.is-light .navbar-brand .navbar-link {
  color: #000000b3;
}

.navbar.is-light .navbar-brand > a.navbar-item:focus, .navbar.is-light .navbar-brand > a.navbar-item:hover, .navbar.is-light .navbar-brand > a.navbar-item.is-active, .navbar.is-light .navbar-brand .navbar-link:focus, .navbar.is-light .navbar-brand .navbar-link:hover, .navbar.is-light .navbar-brand .navbar-link.is-active {
  color: #000000b3;
  background-color: #f2f2f2;
}

.navbar.is-light .navbar-brand .navbar-link:after {
  border-color: #000000b3;
}

.navbar.is-light .navbar-burger {
  color: #000000b3;
}

@media screen and (width >= 980px) {
  .navbar.is-light .navbar-start > .navbar-item, .navbar.is-light .navbar-start .navbar-link, .navbar.is-light .navbar-end > .navbar-item, .navbar.is-light .navbar-end .navbar-link {
    color: #000000b3;
  }

  .navbar.is-light .navbar-start > a.navbar-item:focus, .navbar.is-light .navbar-start > a.navbar-item:hover, .navbar.is-light .navbar-start > a.navbar-item.is-active, .navbar.is-light .navbar-start .navbar-link:focus, .navbar.is-light .navbar-start .navbar-link:hover, .navbar.is-light .navbar-start .navbar-link.is-active, .navbar.is-light .navbar-end > a.navbar-item:focus, .navbar.is-light .navbar-end > a.navbar-item:hover, .navbar.is-light .navbar-end > a.navbar-item.is-active, .navbar.is-light .navbar-end .navbar-link:focus, .navbar.is-light .navbar-end .navbar-link:hover, .navbar.is-light .navbar-end .navbar-link.is-active {
    color: #000000b3;
    background-color: #f2f2f2;
  }

  .navbar.is-light .navbar-start .navbar-link:after, .navbar.is-light .navbar-end .navbar-link:after {
    border-color: #000000b3;
  }

  .navbar.is-light .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-light .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-light .navbar-item.has-dropdown.is-active .navbar-link {
    color: #000000b3;
    background-color: #f2f2f2;
  }

  .navbar.is-light .navbar-dropdown a.navbar-item.is-active {
    color: #000000b3;
    background-color: #fff;
  }
}

.navbar.is-dark {
  color: #fff;
  background-color: #000;
}

.navbar.is-dark .navbar-brand > .navbar-item, .navbar.is-dark .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-dark .navbar-brand > a.navbar-item:focus, .navbar.is-dark .navbar-brand > a.navbar-item:hover, .navbar.is-dark .navbar-brand > a.navbar-item.is-active, .navbar.is-dark .navbar-brand .navbar-link:focus, .navbar.is-dark .navbar-brand .navbar-link:hover, .navbar.is-dark .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #000;
}

.navbar.is-dark .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-dark .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-dark .navbar-start > .navbar-item, .navbar.is-dark .navbar-start .navbar-link, .navbar.is-dark .navbar-end > .navbar-item, .navbar.is-dark .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-dark .navbar-start > a.navbar-item:focus, .navbar.is-dark .navbar-start > a.navbar-item:hover, .navbar.is-dark .navbar-start > a.navbar-item.is-active, .navbar.is-dark .navbar-start .navbar-link:focus, .navbar.is-dark .navbar-start .navbar-link:hover, .navbar.is-dark .navbar-start .navbar-link.is-active, .navbar.is-dark .navbar-end > a.navbar-item:focus, .navbar.is-dark .navbar-end > a.navbar-item:hover, .navbar.is-dark .navbar-end > a.navbar-item.is-active, .navbar.is-dark .navbar-end .navbar-link:focus, .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #000;
  }

  .navbar.is-dark .navbar-start .navbar-link:after, .navbar.is-dark .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-dark .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-dark .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-dark .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-dark .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #000;
  }
}

.navbar.is-primary {
  color: #fff;
  background-color: #e78704;
}

.navbar.is-primary .navbar-brand > .navbar-item, .navbar.is-primary .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover, .navbar.is-primary .navbar-brand > a.navbar-item.is-active, .navbar.is-primary .navbar-brand .navbar-link:focus, .navbar.is-primary .navbar-brand .navbar-link:hover, .navbar.is-primary .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ce7804;
}

.navbar.is-primary .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-primary .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-primary .navbar-start > .navbar-item, .navbar.is-primary .navbar-start .navbar-link, .navbar.is-primary .navbar-end > .navbar-item, .navbar.is-primary .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-primary .navbar-start > a.navbar-item:focus, .navbar.is-primary .navbar-start > a.navbar-item:hover, .navbar.is-primary .navbar-start > a.navbar-item.is-active, .navbar.is-primary .navbar-start .navbar-link:focus, .navbar.is-primary .navbar-start .navbar-link:hover, .navbar.is-primary .navbar-start .navbar-link.is-active, .navbar.is-primary .navbar-end > a.navbar-item:focus, .navbar.is-primary .navbar-end > a.navbar-item:hover, .navbar.is-primary .navbar-end > a.navbar-item.is-active, .navbar.is-primary .navbar-end .navbar-link:focus, .navbar.is-primary .navbar-end .navbar-link:hover, .navbar.is-primary .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ce7804;
  }

  .navbar.is-primary .navbar-start .navbar-link:after, .navbar.is-primary .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-primary .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ce7804;
  }

  .navbar.is-primary .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #e78704;
  }
}

.navbar.is-link {
  color: #fff;
  background-color: #e78704;
}

.navbar.is-link .navbar-brand > .navbar-item, .navbar.is-link .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus, .navbar.is-link .navbar-brand > a.navbar-item:hover, .navbar.is-link .navbar-brand > a.navbar-item.is-active, .navbar.is-link .navbar-brand .navbar-link:focus, .navbar.is-link .navbar-brand .navbar-link:hover, .navbar.is-link .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ce7804;
}

.navbar.is-link .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-link .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-link .navbar-start > .navbar-item, .navbar.is-link .navbar-start .navbar-link, .navbar.is-link .navbar-end > .navbar-item, .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-link .navbar-start > a.navbar-item:focus, .navbar.is-link .navbar-start > a.navbar-item:hover, .navbar.is-link .navbar-start > a.navbar-item.is-active, .navbar.is-link .navbar-start .navbar-link:focus, .navbar.is-link .navbar-start .navbar-link:hover, .navbar.is-link .navbar-start .navbar-link.is-active, .navbar.is-link .navbar-end > a.navbar-item:focus, .navbar.is-link .navbar-end > a.navbar-item:hover, .navbar.is-link .navbar-end > a.navbar-item.is-active, .navbar.is-link .navbar-end .navbar-link:focus, .navbar.is-link .navbar-end .navbar-link:hover, .navbar.is-link .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ce7804;
  }

  .navbar.is-link .navbar-start .navbar-link:after, .navbar.is-link .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ce7804;
  }

  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #e78704;
  }
}

.navbar.is-info {
  color: #fff;
  background-color: #00b3cc;
}

.navbar.is-info .navbar-brand > .navbar-item, .navbar.is-info .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-info .navbar-brand > a.navbar-item:focus, .navbar.is-info .navbar-brand > a.navbar-item:hover, .navbar.is-info .navbar-brand > a.navbar-item.is-active, .navbar.is-info .navbar-brand .navbar-link:focus, .navbar.is-info .navbar-brand .navbar-link:hover, .navbar.is-info .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #009db3;
}

.navbar.is-info .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-info .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-info .navbar-start > .navbar-item, .navbar.is-info .navbar-start .navbar-link, .navbar.is-info .navbar-end > .navbar-item, .navbar.is-info .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-info .navbar-start > a.navbar-item:focus, .navbar.is-info .navbar-start > a.navbar-item:hover, .navbar.is-info .navbar-start > a.navbar-item.is-active, .navbar.is-info .navbar-start .navbar-link:focus, .navbar.is-info .navbar-start .navbar-link:hover, .navbar.is-info .navbar-start .navbar-link.is-active, .navbar.is-info .navbar-end > a.navbar-item:focus, .navbar.is-info .navbar-end > a.navbar-item:hover, .navbar.is-info .navbar-end > a.navbar-item.is-active, .navbar.is-info .navbar-end .navbar-link:focus, .navbar.is-info .navbar-end .navbar-link:hover, .navbar.is-info .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #009db3;
  }

  .navbar.is-info .navbar-start .navbar-link:after, .navbar.is-info .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-info .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-info .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-info .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #009db3;
  }

  .navbar.is-info .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #00b3cc;
  }
}

.navbar.is-success {
  color: #fff;
  background-color: #5bc72b;
}

.navbar.is-success .navbar-brand > .navbar-item, .navbar.is-success .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-success .navbar-brand > a.navbar-item:focus, .navbar.is-success .navbar-brand > a.navbar-item:hover, .navbar.is-success .navbar-brand > a.navbar-item.is-active, .navbar.is-success .navbar-brand .navbar-link:focus, .navbar.is-success .navbar-brand .navbar-link:hover, .navbar.is-success .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #51b226;
}

.navbar.is-success .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-success .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-success .navbar-start > .navbar-item, .navbar.is-success .navbar-start .navbar-link, .navbar.is-success .navbar-end > .navbar-item, .navbar.is-success .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-success .navbar-start > a.navbar-item:focus, .navbar.is-success .navbar-start > a.navbar-item:hover, .navbar.is-success .navbar-start > a.navbar-item.is-active, .navbar.is-success .navbar-start .navbar-link:focus, .navbar.is-success .navbar-start .navbar-link:hover, .navbar.is-success .navbar-start .navbar-link.is-active, .navbar.is-success .navbar-end > a.navbar-item:focus, .navbar.is-success .navbar-end > a.navbar-item:hover, .navbar.is-success .navbar-end > a.navbar-item.is-active, .navbar.is-success .navbar-end .navbar-link:focus, .navbar.is-success .navbar-end .navbar-link:hover, .navbar.is-success .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #51b226;
  }

  .navbar.is-success .navbar-start .navbar-link:after, .navbar.is-success .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-success .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-success .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-success .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #51b226;
  }

  .navbar.is-success .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #5bc72b;
  }
}

.navbar.is-warning {
  color: #fff;
  background-color: #d3007e;
}

.navbar.is-warning .navbar-brand > .navbar-item, .navbar.is-warning .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-warning .navbar-brand > a.navbar-item:focus, .navbar.is-warning .navbar-brand > a.navbar-item:hover, .navbar.is-warning .navbar-brand > a.navbar-item.is-active, .navbar.is-warning .navbar-brand .navbar-link:focus, .navbar.is-warning .navbar-brand .navbar-link:hover, .navbar.is-warning .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ba006f;
}

.navbar.is-warning .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-warning .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-warning .navbar-start > .navbar-item, .navbar.is-warning .navbar-start .navbar-link, .navbar.is-warning .navbar-end > .navbar-item, .navbar.is-warning .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-warning .navbar-start > a.navbar-item:focus, .navbar.is-warning .navbar-start > a.navbar-item:hover, .navbar.is-warning .navbar-start > a.navbar-item.is-active, .navbar.is-warning .navbar-start .navbar-link:focus, .navbar.is-warning .navbar-start .navbar-link:hover, .navbar.is-warning .navbar-start .navbar-link.is-active, .navbar.is-warning .navbar-end > a.navbar-item:focus, .navbar.is-warning .navbar-end > a.navbar-item:hover, .navbar.is-warning .navbar-end > a.navbar-item.is-active, .navbar.is-warning .navbar-end .navbar-link:focus, .navbar.is-warning .navbar-end .navbar-link:hover, .navbar.is-warning .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ba006f;
  }

  .navbar.is-warning .navbar-start .navbar-link:after, .navbar.is-warning .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-warning .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-warning .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-warning .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ba006f;
  }

  .navbar.is-warning .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #d3007e;
  }
}

.navbar.is-danger {
  color: #fff;
  background-color: #d30000;
}

.navbar.is-danger .navbar-brand > .navbar-item, .navbar.is-danger .navbar-brand .navbar-link {
  color: #fff;
}

.navbar.is-danger .navbar-brand > a.navbar-item:focus, .navbar.is-danger .navbar-brand > a.navbar-item:hover, .navbar.is-danger .navbar-brand > a.navbar-item.is-active, .navbar.is-danger .navbar-brand .navbar-link:focus, .navbar.is-danger .navbar-brand .navbar-link:hover, .navbar.is-danger .navbar-brand .navbar-link.is-active {
  color: #fff;
  background-color: #ba0000;
}

.navbar.is-danger .navbar-brand .navbar-link:after {
  border-color: #fff;
}

.navbar.is-danger .navbar-burger {
  color: #fff;
}

@media screen and (width >= 980px) {
  .navbar.is-danger .navbar-start > .navbar-item, .navbar.is-danger .navbar-start .navbar-link, .navbar.is-danger .navbar-end > .navbar-item, .navbar.is-danger .navbar-end .navbar-link {
    color: #fff;
  }

  .navbar.is-danger .navbar-start > a.navbar-item:focus, .navbar.is-danger .navbar-start > a.navbar-item:hover, .navbar.is-danger .navbar-start > a.navbar-item.is-active, .navbar.is-danger .navbar-start .navbar-link:focus, .navbar.is-danger .navbar-start .navbar-link:hover, .navbar.is-danger .navbar-start .navbar-link.is-active, .navbar.is-danger .navbar-end > a.navbar-item:focus, .navbar.is-danger .navbar-end > a.navbar-item:hover, .navbar.is-danger .navbar-end > a.navbar-item.is-active, .navbar.is-danger .navbar-end .navbar-link:focus, .navbar.is-danger .navbar-end .navbar-link:hover, .navbar.is-danger .navbar-end .navbar-link.is-active {
    color: #fff;
    background-color: #ba0000;
  }

  .navbar.is-danger .navbar-start .navbar-link:after, .navbar.is-danger .navbar-end .navbar-link:after {
    border-color: #fff;
  }

  .navbar.is-danger .navbar-item.has-dropdown:focus .navbar-link, .navbar.is-danger .navbar-item.has-dropdown:hover .navbar-link, .navbar.is-danger .navbar-item.has-dropdown.is-active .navbar-link {
    color: #fff;
    background-color: #ba0000;
  }

  .navbar.is-danger .navbar-dropdown a.navbar-item.is-active {
    color: #fff;
    background-color: #d30000;
  }
}

.navbar > .container {
  align-items: stretch;
  width: 100%;
  min-height: 50px;
  display: flex;
}

.navbar.has-shadow {
  box-shadow: 0 #0000;
}

.navbar.is-fixed-bottom, .navbar.is-fixed-top {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
}

.navbar.is-fixed-bottom {
  bottom: 0;
}

.navbar.is-fixed-bottom.has-shadow {
  box-shadow: 0 #0000;
}

.navbar.is-fixed-top {
  top: 0;
}

html.has-navbar-fixed-top, body.has-navbar-fixed-top {
  padding-top: 50px;
}

html.has-navbar-fixed-bottom, body.has-navbar-fixed-bottom {
  padding-bottom: 50px;
}

.navbar-brand, .navbar-tabs {
  flex-shrink: 0;
  align-items: stretch;
  min-height: 50px;
  display: flex;
}

.navbar-brand a.navbar-item:focus, .navbar-brand a.navbar-item:hover {
  background-color: #0000;
}

.navbar-tabs {
  -webkit-overflow-scrolling: touch;
  max-width: 100vw;
  overflow: auto hidden;
}

.navbar-burger {
  color: #e78704;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: auto;
  display: block;
  position: relative;
}

.navbar-burger span {
  transform-origin: center;
  background-color: currentColor;
  width: 16px;
  height: 1px;
  transition-property: background-color, opacity, transform, -webkit-transform;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  display: block;
  position: absolute;
  left: calc(50% - 8px);
}

.navbar-burger span:first-child {
  top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger:hover {
  background-color: #0000000d;
}

.navbar-burger.is-active span:first-child {
  transform: translateY(5px)rotate(45deg);
}

.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5px)rotate(-45deg);
}

.navbar-menu {
  display: none;
}

.navbar-item, .navbar-link {
  color: #e78704;
  padding: .5rem .75rem;
  line-height: 1.5;
  display: block;
  position: relative;
}

.navbar-item .icon:only-child, .navbar-link .icon:only-child {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

a.navbar-item, .navbar-link {
  cursor: pointer;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: #000;
  background-color: #0000;
}

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.navbar-item img {
  max-height: 50px;
}

.navbar-item.has-dropdown {
  padding: 0;
}

.navbar-item.is-expanded {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-item.is-tab {
  border-bottom: 1px solid #0000;
  min-height: 50px;
  padding-bottom: calc(.5rem - 1px);
}

.navbar-item.is-tab:focus, .navbar-item.is-tab:hover {
  background-color: #0000;
  border-bottom-color: #e78704;
}

.navbar-item.is-tab.is-active {
  color: #e78704;
  background-color: #0000;
  border-bottom: 2px solid #e78704;
  padding-bottom: calc(.5rem - 2px);
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;
}

.navbar-link:not(.is-arrowless):after {
  border-color: #e78704;
  margin-top: -.375em;
  right: 1.125em;
}

.navbar-dropdown {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
}

.navbar-dropdown .navbar-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-divider {
  background-color: #e6e6e6;
  border: none;
  height: 2px;
  margin: .5rem 0;
  display: none;
}

@media screen and (width <= 979px) {
  .navbar > .container {
    display: block;
  }

  .navbar-brand .navbar-item, .navbar-tabs .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link:after {
    display: none;
  }

  .navbar-menu {
    background-color: #0000;
    padding: .5rem 0;
    box-shadow: 0 8px 16px #0000001a;
  }

  .navbar-menu.is-active {
    display: block;
  }

  .navbar.is-fixed-bottom-touch, .navbar.is-fixed-top-touch {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-touch {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-touch.has-shadow {
    box-shadow: 0 -2px 3px #0000001a;
  }

  .navbar.is-fixed-top-touch {
    top: 0;
  }

  .navbar.is-fixed-top .navbar-menu, .navbar.is-fixed-top-touch .navbar-menu {
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 50px);
    overflow: auto;
  }

  html.has-navbar-fixed-top-touch, body.has-navbar-fixed-top-touch {
    padding-top: 50px;
  }

  html.has-navbar-fixed-bottom-touch, body.has-navbar-fixed-bottom-touch {
    padding-bottom: 50px;
  }
}

@media screen and (width >= 980px) {
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 50px;
  }

  .navbar.is-spaced {
    padding: 0 2rem;
  }

  .navbar.is-spaced .navbar-start, .navbar.is-spaced .navbar-end {
    align-items: center;
  }

  .navbar.is-spaced a.navbar-item, .navbar.is-spaced .navbar-link {
    border-radius: 10px;
  }

  .navbar.is-transparent a.navbar-item:focus, .navbar.is-transparent a.navbar-item:hover, .navbar.is-transparent a.navbar-item.is-active, .navbar.is-transparent .navbar-link:focus, .navbar.is-transparent .navbar-link:hover, .navbar.is-transparent .navbar-link.is-active, .navbar.is-transparent .navbar-item.has-dropdown.is-active .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:focus-within .navbar-link, .navbar.is-transparent .navbar-item.has-dropdown.is-hoverable:hover .navbar-link {
    background-color: #0000 !important;
  }

  .navbar.is-transparent .navbar-dropdown a.navbar-item:focus, .navbar.is-transparent .navbar-dropdown a.navbar-item:hover, .navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
    color: #000;
    background-color: #0000;
  }

  .navbar-burger {
    display: none;
  }

  .navbar-item, .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item.has-dropdown {
    align-items: stretch;
  }

  .navbar-item.has-dropdown-up .navbar-link:after {
    transform: rotate(135deg)translate(.25em, -.25em);
  }

  .navbar-item.has-dropdown-up .navbar-dropdown {
    border-top: none;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    top: auto;
    bottom: 100%;
    box-shadow: 0 -8px 8px #0000001a;
  }

  .navbar-item.is-active .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block;
  }

  .navbar.is-spaced .navbar-item.is-active .navbar-dropdown, .navbar-item.is-active .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus .navbar-dropdown, .navbar-item.is-hoverable:focus .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:focus-within .navbar-dropdown, .navbar-item.is-hoverable:focus-within .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-item.is-hoverable:hover .navbar-dropdown, .navbar-item.is-hoverable:hover .navbar-dropdown.is-boxed {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    z-index: 20;
    background-color: #fff;
    border-top: none;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    min-width: 100%;
    font-size: .875rem;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 8px 8px #0000001a;
  }

  .navbar-dropdown .navbar-item {
    white-space: nowrap;
    padding: .375rem 1rem;
  }

  .navbar-dropdown a.navbar-item {
    padding-right: 3rem;
  }

  .navbar-dropdown a.navbar-item:focus, .navbar-dropdown a.navbar-item:hover, .navbar-dropdown a.navbar-item.is-active {
    color: #000;
    background-color: #0000;
  }

  .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
    opacity: 0;
    pointer-events: none;
    border-top: none;
    border-radius: 10px;
    transition-property: opacity, transform, -webkit-transform;
    transition-duration: .25s;
    display: block;
    top: calc(100% - 4px);
    transform: translateY(-5px);
    box-shadow: 0 0 20px #6a3e021a;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: 0;
  }

  .navbar-divider {
    display: block;
  }

  .navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
    margin-left: -.75rem;
  }

  .navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
    margin-right: -.75rem;
  }

  .navbar.is-fixed-bottom-desktop, .navbar.is-fixed-top-desktop {
    z-index: 30;
    position: fixed;
    left: 0;
    right: 0;
  }

  .navbar.is-fixed-bottom-desktop {
    bottom: 0;
  }

  .navbar.is-fixed-bottom-desktop.has-shadow {
    box-shadow: 0 -2px 3px #0000001a;
  }

  .navbar.is-fixed-top-desktop {
    top: 0;
  }

  html.has-navbar-fixed-top-desktop, body.has-navbar-fixed-top-desktop {
    padding-top: 50px;
  }

  html.has-navbar-fixed-bottom-desktop, body.has-navbar-fixed-bottom-desktop {
    padding-bottom: 50px;
  }

  html.has-spaced-navbar-fixed-top, body.has-spaced-navbar-fixed-top {
    padding-top: 50px;
  }

  html.has-spaced-navbar-fixed-bottom, body.has-spaced-navbar-fixed-bottom {
    padding-bottom: 50px;
  }

  a.navbar-item.is-active, .navbar-link.is-active {
    color: #000;
  }

  a.navbar-item.is-active:not(:focus):not(:hover), .navbar-link.is-active:not(:focus):not(:hover), .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: #0000;
  }
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 50px);
}

.column {
  flex: 1 1 0;
  padding: 25px;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.33333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (width <= 768px) {
  .column.is-narrow-mobile {
    flex: none;
  }

  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (width >= 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (width <= 979px) {
  .column.is-narrow-touch {
    flex: none;
  }

  .column.is-full-touch {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-touch {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (width >= 980px) {
  .column.is-narrow-desktop {
    flex: none;
  }

  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (width >= 1172px) {
  .column.is-narrow-widescreen {
    flex: none;
  }

  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (width >= 1364px) {
  .column.is-narrow-fullhd {
    flex: none;
  }

  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }

  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }

  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }

  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }

  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    flex: none;
    width: 8.33333%;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    flex: none;
    width: 16.6667%;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    flex: none;
    width: 33.3333%;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    flex: none;
    width: 41.6667%;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    flex: none;
    width: 58.3333%;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    flex: none;
    width: 66.6667%;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    flex: none;
    width: 83.3333%;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    flex: none;
    width: 91.6667%;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -25px;
  margin-left: -25px;
  margin-right: -25px;
}

.columns:last-child {
  margin-bottom: -25px;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 25px);
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (width >= 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (width >= 980px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (width <= 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 769px) and (width <= 979px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (width <= 979px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 980px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 980px) and (width <= 1171px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1172px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1172px) and (width <= 1363px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (width >= 1364px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.section {
  padding: 50px 25px;
}

@media screen and (width >= 980px) {
  .section.is-medium {
    padding: 100px 25px;
  }

  .section.is-large {
    padding: 200px 25px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
}

h1 {
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
}

h2, h3, h4, h5, h6 {
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

h1 {
  margin-bottom: .5em;
  font-size: 3.2rem;
  line-height: 1.2;
}

h2 {
  margin-bottom: .5em;
  font-size: 2.4rem;
  line-height: 1.2;
}

@media screen and (width <= 768px) {
  h2 {
    font-size: 1.8rem;
  }
}

h3 {
  margin-bottom: .5em;
  font-size: 1.8rem;
  line-height: 1.5;
}

h4 {
  margin-bottom: .5em;
  font-size: 1.6rem;
  line-height: 1.5;
}

h5 {
  font-size: 1.4rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.5;
}

::selection {
  color: #000;
  text-shadow: none;
  background: #f8ff00;
}

.button {
  z-index: 2;
  box-sizing: border-box;
  transition: all .25s ease-in-out;
  position: relative;
  overflow: hidden;
}

.button:before {
  content: "";
  z-index: -1;
  background-color: #e78704;
  border-radius: 10rem;
  width: 0%;
  height: 0%;
  transition: all .25s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button:after {
  content: "";
  opacity: 0;
  background-color: #fff;
  border-radius: 10rem;
  width: 100%;
  height: 100%;
  transition: all .25s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 5px #fff;
}

.button .icon {
  background-color: #e78704;
}

.button:hover {
  color: #fff;
  transition: all .25s ease-in-out;
  box-shadow: 0 0 20px #6a3e021a;
}

.button:hover:before {
  width: 100%;
  height: 100%;
}

.button:hover .icon {
  background-color: #fff;
}

.button:active:after {
  opacity: 1;
  width: 0;
  height: 0;
  transition: all;
  box-shadow: 0 0 10px #fff;
}

.button.button--direction-right:before {
  height: 100%;
  top: 0;
  left: 0;
  transform: translate(0);
}

.button.button--direction-left:before {
  height: 100%;
  top: 0;
  left: auto;
  right: 0;
  transform: translate(0);
}

.button.button--direction-down:before {
  width: 100%;
  top: 0;
  left: 0;
  transform: translate(0);
}

.button.button--direction-up:before {
  width: 100%;
  top: auto;
  bottom: 0;
  left: 0;
  transform: translate(0);
}

.button.is-text {
  text-decoration: none;
}

.button.is-text:before, .button.is-text:after {
  background-color: #fff;
}

.button.is-text:hover {
  color: #fff;
}

.button.is-text:hover:before {
  background-color: #e78704;
}

.button.is-primary:before {
  background-color: #fff;
}

.button.is-primary:after {
  background-color: #e78704;
  box-shadow: 0 0 10px 5px #e78704;
}

.button.is-primary .icon {
  background-color: #fff;
}

.button.is-primary:hover {
  color: #e78704;
}

.button.is-primary:hover:after {
  box-shadow: 0 0 10px #e78704;
}

.button.is-primary:hover .icon {
  background-color: #e78704;
}

.button.is-primary.is-light {
  color: #e78704 !important;
}

.button.is-primary.is-light:before {
  background-color: #fff;
}

.button.is-primary.is-light .icon {
  background-color: #e78704;
}

.button.is-info:before {
  background-color: #fff;
}

.button.is-info:after {
  background-color: #00b3cc;
  box-shadow: 0 0 10px 5px #00b3cc;
}

.button.is-info .icon {
  background-color: #fff;
}

.button.is-info:hover {
  color: #00b3cc;
}

.button.is-info:hover:after {
  box-shadow: 0 0 10px #00b3cc;
}

.button.is-info:hover .icon {
  background-color: #00b3cc;
}

.button.is-info.is-light {
  color: #00b3cc !important;
}

.button.is-info.is-light:before {
  background-color: #fff;
}

.button.is-info.is-light .icon {
  background-color: #00b3cc;
}

.button.is-success:before {
  background-color: #fff;
}

.button.is-success:after {
  background-color: #5bc72b;
  box-shadow: 0 0 10px 5px #5bc72b;
}

.button.is-success .icon {
  background-color: #fff;
}

.button.is-success:hover {
  color: #5bc72b;
}

.button.is-success:hover:after {
  box-shadow: 0 0 10px #5bc72b;
}

.button.is-success:hover .icon {
  background-color: #5bc72b;
}

.button.is-success.is-light {
  color: #5bc72b !important;
}

.button.is-success.is-light:before {
  background-color: #fff;
}

.button.is-success.is-light .icon {
  background-color: #5bc72b;
}

.button.is-warning:before {
  background-color: #fff;
}

.button.is-warning:after {
  background-color: #d3007e;
  box-shadow: 0 0 10px 5px #d3007e;
}

.button.is-warning .icon {
  background-color: #fff;
}

.button.is-warning:hover {
  color: #d3007e;
}

.button.is-warning:hover:after {
  box-shadow: 0 0 10px #d3007e;
}

.button.is-warning:hover .icon {
  background-color: #d3007e;
}

.button.is-warning.is-light {
  color: #d3007e !important;
}

.button.is-warning.is-light:before {
  background-color: #fff;
}

.button.is-warning.is-light .icon {
  background-color: #d3007e;
}

.button.is-danger:before {
  background-color: #fff;
}

.button.is-danger:after {
  background-color: #d30000;
  box-shadow: 0 0 10px 5px #d30000;
}

.button.is-danger .icon {
  background-color: #fff;
}

.button.is-danger:hover {
  color: #d30000;
}

.button.is-danger:hover:after {
  box-shadow: 0 0 10px #d30000;
}

.button.is-danger:hover .icon {
  background-color: #d30000;
}

.button.is-danger.is-light {
  color: #d30000 !important;
}

.button.is-danger.is-light:before {
  background-color: #fff;
}

.button.is-danger.is-light .icon {
  background-color: #d30000;
}

.container.is-max-tablet {
  max-width: 769px;
}

.container.is-max-desktop {
  max-width: 980px;
}

.icon {
  background-color: #000;
  transition: all .125s ease-in-out;
}

.icon.is-black, .icon.is-dark {
  background-color: #000;
}

.icon.is-light, .icon.is-white {
  background-color: #fff;
}

.icon.is-primary, .icon.is-link {
  background-color: #e78704;
}

.icon.is-info {
  background-color: #00b3cc;
}

.icon.is-success {
  background-color: #5bc72b;
}

.icon.is-warning {
  background-color: #d3007e;
}

.icon.is-danger {
  background-color: #d30000;
}

.icon.icon-text {
  vertical-align: sub;
  width: 1.2em;
  height: 1.2em;
}

.licon-arrow-circle-left {
  -webkit-mask: url("arrow-circle-left.02863a90.svg");
  mask: url("arrow-circle-left.02863a90.svg");
}

.licon-arrow-circle-right {
  -webkit-mask: url("arrow-circle-right.192184b2.svg");
  mask: url("arrow-circle-right.192184b2.svg");
}

.licon-arrow-circle-up {
  -webkit-mask: url("arrow-circle-up.4d91c3dd.svg");
  mask: url("arrow-circle-up.4d91c3dd.svg");
}

.licon-arrow-circle-down {
  -webkit-mask: url("arrow-circle-down.e6136919.svg");
  mask: url("arrow-circle-down.e6136919.svg");
}

.licon-arrow-left {
  -webkit-mask: url("arrow-left.1b6ea76b.svg");
  mask: url("arrow-left.1b6ea76b.svg");
}

.licon-arrow-right {
  -webkit-mask: url("arrow-right.026567c4.svg");
  mask: url("arrow-right.026567c4.svg");
}

.licon-arrow-up {
  -webkit-mask: url("arrow-up.5088afb6.svg");
  mask: url("arrow-up.5088afb6.svg");
}

.licon-arrow-down {
  -webkit-mask: url("arrow-down.24c26c06.svg");
  mask: url("arrow-down.24c26c06.svg");
}

.licon-caret-circle-left {
  -webkit-mask: url("caret-circle-left.cd9b437f.svg");
  mask: url("caret-circle-left.cd9b437f.svg");
}

.licon-caret-circle-right {
  -webkit-mask: url("caret-circle-right.a2422a22.svg");
  mask: url("caret-circle-right.a2422a22.svg");
}

.licon-caret-circle-up {
  -webkit-mask: url("caret-circle-up.3ed4ba01.svg");
  mask: url("caret-circle-up.3ed4ba01.svg");
}

.licon-caret-circle-down {
  -webkit-mask: url("caret-circle-down.6729d00c.svg");
  mask: url("caret-circle-down.6729d00c.svg");
}

.licon-caret-left {
  -webkit-mask: url("caret-left.1f0ac1f5.svg");
  mask: url("caret-left.1f0ac1f5.svg");
}

.licon-caret-right {
  -webkit-mask: url("caret-right.800022de.svg");
  mask: url("caret-right.800022de.svg");
}

.licon-caret-up {
  -webkit-mask: url("caret-up.2452d968.svg");
  mask: url("caret-up.2452d968.svg");
}

.licon-caret-down {
  -webkit-mask: url("caret-down.36c8b3bf.svg");
  mask: url("caret-down.36c8b3bf.svg");
}

.licon-number-circle-zero {
  -webkit-mask: url("number-circle-zero.27099591.svg");
  mask: url("number-circle-zero.27099591.svg");
}

.licon-number-circle-one {
  -webkit-mask: url("number-circle-one.c3e8e060.svg");
  mask: url("number-circle-one.c3e8e060.svg");
}

.licon-number-circle-two {
  -webkit-mask: url("number-circle-two.3c62f12a.svg");
  mask: url("number-circle-two.3c62f12a.svg");
}

.licon-number-circle-three {
  -webkit-mask: url("number-circle-three.57018a8b.svg");
  mask: url("number-circle-three.57018a8b.svg");
}

.licon-number-circle-four {
  -webkit-mask: url("number-circle-four.435842e2.svg");
  mask: url("number-circle-four.435842e2.svg");
}

.licon-number-circle-five {
  -webkit-mask: url("number-circle-five.2aeba92a.svg");
  mask: url("number-circle-five.2aeba92a.svg");
}

.licon-number-circle-six {
  -webkit-mask: url("number-circle-six.11476f09.svg");
  mask: url("number-circle-six.11476f09.svg");
}

.licon-number-circle-seven {
  -webkit-mask: url("number-circle-seven.be63df12.svg");
  mask: url("number-circle-seven.be63df12.svg");
}

.licon-number-circle-eight {
  -webkit-mask: url("number-circle-eight.d61c7a3e.svg");
  mask: url("number-circle-eight.d61c7a3e.svg");
}

.licon-number-circle-nine {
  -webkit-mask: url("number-circle-nine.cec152e9.svg");
  mask: url("number-circle-nine.cec152e9.svg");
}

.licon-chat-circle-text {
  -webkit-mask: url("chat-circle-text.776550fd.svg");
  mask: url("chat-circle-text.776550fd.svg");
}

.licon-arrow-square-in {
  -webkit-mask: url("arrow-square-in.846790f3.svg");
  mask: url("arrow-square-in.846790f3.svg");
}

.licon-arrow-square-out {
  -webkit-mask: url("arrow-square-out.7092c74b.svg");
  mask: url("arrow-square-out.7092c74b.svg");
}

.licon-arrows-clockwise {
  -webkit-mask: url("arrows-clockwise.601ef73a.svg");
  mask: url("arrows-clockwise.601ef73a.svg");
}

.licon-arrow-arc-left {
  -webkit-mask: url("arrow-arc-left.fabdf54c.svg");
  mask: url("arrow-arc-left.fabdf54c.svg");
}

.licon-arrow-arc-right {
  -webkit-mask: url("arrow-arc-right.9a90d219.svg");
  mask: url("arrow-arc-right.9a90d219.svg");
}

.licon-article {
  -webkit-mask: url("article.fd323ff0.svg");
  mask: url("article.fd323ff0.svg");
}

.licon-bird {
  -webkit-mask: url("bird.c7adaccd.svg");
  mask: url("bird.c7adaccd.svg");
}

.licon-chart-bar {
  -webkit-mask: url("chart-bar.309c4186.svg");
  mask: url("chart-bar.309c4186.svg");
}

.licon-check {
  -webkit-mask: url("check.cbc21976.svg");
  mask: url("check.cbc21976.svg");
}

.licon-check-circle {
  -webkit-mask: url("check-circle.77886938.svg");
  mask: url("check-circle.77886938.svg");
}

.licon-pause-circle {
  -webkit-mask: url("pause-circle.4812b893.svg");
  mask: url("pause-circle.4812b893.svg");
}

.licon-play-circle {
  -webkit-mask: url("play-circle.99d78dc8.svg");
  mask: url("play-circle.99d78dc8.svg");
}

.licon-stop-circle {
  -webkit-mask: url("stop-circle.948a0836.svg");
  mask: url("stop-circle.948a0836.svg");
}

.licon-pause {
  -webkit-mask: url("pause.1531d425.svg");
  mask: url("pause.1531d425.svg");
}

.licon-play {
  -webkit-mask: url("play.d2953f2d.svg");
  mask: url("play.d2953f2d.svg");
}

.licon-stop {
  -webkit-mask: url("stop.ec5bedb2.svg");
  mask: url("stop.ec5bedb2.svg");
}

.licon-cloud-arrow-down {
  -webkit-mask: url("cloud-arrow-down.fc4ba7e9.svg");
  mask: url("cloud-arrow-down.fc4ba7e9.svg");
}

.licon-cloud-arrow-up {
  -webkit-mask: url("cloud-arrow-up.1131acdd.svg");
  mask: url("cloud-arrow-up.1131acdd.svg");
}

.licon-download-simple {
  -webkit-mask: url("download-simple.5b4358b4.svg");
  mask: url("download-simple.5b4358b4.svg");
}

.licon-copyright {
  -webkit-mask: url("copyright.d613e902.svg");
  mask: url("copyright.d613e902.svg");
}

.licon-crown {
  -webkit-mask: url("crown.99e32c44.svg");
  mask: url("crown.99e32c44.svg");
}

.licon-fingerprint {
  -webkit-mask: url("fingerprint.d09282de.svg");
  mask: url("fingerprint.d09282de.svg");
}

.licon-gear {
  -webkit-mask: url("gear.bc23e0e4.svg");
  mask: url("gear.bc23e0e4.svg");
}

.licon-ghost {
  -webkit-mask: url("ghost.33f26d13.svg");
  mask: url("ghost.33f26d13.svg");
}

.licon-hands-clapping {
  -webkit-mask: url("hands-clapping.7faa36bd.svg");
  mask: url("hands-clapping.7faa36bd.svg");
}

.licon-heart {
  -webkit-mask: url("heart.6b0ddb14.svg");
  mask: url("heart.6b0ddb14.svg");
}

.licon-house {
  -webkit-mask: url("house.609c8c39.svg");
  mask: url("house.609c8c39.svg");
}

.licon-info {
  -webkit-mask: url("info.8c613d19.svg");
  mask: url("info.8c613d19.svg");
}

.licon-question {
  -webkit-mask: url("question.f4abf14c.svg");
  mask: url("question.f4abf14c.svg");
}

.licon-warning {
  -webkit-mask: url("warning.161b2e34.svg");
  mask: url("warning.161b2e34.svg");
}

.licon-lightbulb {
  -webkit-mask: url("lightbulb.a02b3114.svg");
  mask: url("lightbulb.a02b3114.svg");
}

.licon-lightning {
  -webkit-mask: url("lightning.5ca15306.svg");
  mask: url("lightning.5ca15306.svg");
}

.licon-medal {
  -webkit-mask: url("medal.a422c7b5.svg");
  mask: url("medal.a422c7b5.svg");
}

.licon-trophy {
  -webkit-mask: url("trophy.5023b959.svg");
  mask: url("trophy.5023b959.svg");
}

.licon-mouse {
  -webkit-mask: url("mouse.b248a6b4.svg");
  mask: url("mouse.b248a6b4.svg");
}

.licon-smiley {
  -webkit-mask: url("smiley.0f9ba1e9.svg");
  mask: url("smiley.0f9ba1e9.svg");
}

.licon-student {
  -webkit-mask: url("student.f0adb212.svg");
  mask: url("student.f0adb212.svg");
}

.licon-tag {
  -webkit-mask: url("tag.60595b61.svg");
  mask: url("tag.60595b61.svg");
}

.licon-alarm {
  -webkit-mask: url("alarm.b172ab95.svg");
  mask: url("alarm.b172ab95.svg");
}

.licon-star {
  -webkit-mask: url("star.0b964a1c.svg");
  mask: url("star.0b964a1c.svg");
}

.licon-robot {
  -webkit-mask: url("robot.369fb5eb.svg");
  mask: url("robot.369fb5eb.svg");
}

.licon-list {
  -webkit-mask: url("list.4f45b09c.svg");
  mask: url("list.4f45b09c.svg");
}

.licon-key {
  -webkit-mask: url("key.7cc804bd.svg");
  mask: url("key.7cc804bd.svg");
}

.licon-asterisk {
  -webkit-mask: url("asterisk.4b9bbd3f.svg");
  mask: url("asterisk.4b9bbd3f.svg");
}

.licon-book-open {
  -webkit-mask: url("book-open.36f9cc16.svg");
  mask: url("book-open.36f9cc16.svg");
}

.licon-file-pdf {
  -webkit-mask: url("file-pdf.0f968cba.svg");
  mask: url("file-pdf.0f968cba.svg");
}

.licon-copy-simple {
  -webkit-mask: url("copy-simple.7760f930.svg");
  mask: url("copy-simple.7760f930.svg");
}

.licon-x {
  -webkit-mask: url("x.9fabc06f.svg");
  mask: url("x.9fabc06f.svg");
}

.licon-x-circle {
  -webkit-mask: url("x-circle.631e33ae.svg");
  mask: url("x-circle.631e33ae.svg");
}

.licon-at {
  -webkit-mask: url("at.57c4366a.svg");
  mask: url("at.57c4366a.svg");
}

.licon-bell-ringing {
  -webkit-mask: url("bell-ringing.4efa8dc0.svg");
  mask: url("bell-ringing.4efa8dc0.svg");
}

.licon-brain {
  -webkit-mask: url("brain.7bfe018e.svg");
  mask: url("brain.7bfe018e.svg");
}

.licon-circle-wavy-check {
  -webkit-mask: url("circle-wavy-check.7b9ce3dd.svg");
  mask: url("circle-wavy-check.7b9ce3dd.svg");
}

.licon-circle-wavy-question {
  -webkit-mask: url("circle-wavy-question.5c0d9a4a.svg");
  mask: url("circle-wavy-question.5c0d9a4a.svg");
}

.licon-circle-wavy-warning {
  -webkit-mask: url("circle-wavy-warning.d26b1dc5.svg");
  mask: url("circle-wavy-warning.d26b1dc5.svg");
}

.licon-plus-circle {
  -webkit-mask: url("plus-circle.ab3fadee.svg");
  mask: url("plus-circle.ab3fadee.svg");
}

.licon-minus-circle {
  -webkit-mask: url("minus-circle.edbdd829.svg");
  mask: url("minus-circle.edbdd829.svg");
}

.licon-rocket {
  -webkit-mask: url("rocket.2e570fec.svg");
  mask: url("rocket.2e570fec.svg");
}

.licon-sticker {
  -webkit-mask: url("sticker.6c615b96.svg");
  mask: url("sticker.6c615b96.svg");
}

.licon-target {
  -webkit-mask: url("target.89728f28.svg");
  mask: url("target.89728f28.svg");
}

.licon-thumbs-up {
  -webkit-mask: url("thumbs-up.3de2f265.svg");
  mask: url("thumbs-up.3de2f265.svg");
}

.licon-thumbs-down {
  -webkit-mask: url("thumbs-down.b7123b8f.svg");
  mask: url("thumbs-down.b7123b8f.svg");
}

.licon-trash {
  -webkit-mask: url("trash.ca342337.svg");
  mask: url("trash.ca342337.svg");
}

.licon-user {
  -webkit-mask: url("user.837821ad.svg");
  mask: url("user.837821ad.svg");
}

.licon-browser {
  -webkit-mask: url("browser.07d0accd.svg");
  mask: url("browser.07d0accd.svg");
}

.licon-envelope-simple {
  -webkit-mask: url("envelope-simple.b2579db8.svg");
  mask: url("envelope-simple.b2579db8.svg");
}

.licon-printer {
  -webkit-mask: url("printer.d69c55d4.svg");
  mask: url("printer.d69c55d4.svg");
}

.licon-paper-plane-right {
  -webkit-mask: url("paper-plane-right.0ec9a077.svg");
  mask: url("paper-plane-right.0ec9a077.svg");
}

.notification {
  box-shadow: 0 0 20px #6a3e021a;
}

.box .notification, .modal-card .notification {
  box-shadow: none;
}

.checkbox, .radio {
  line-height: 1.5;
}

.radio + .radio {
  margin-left: 0;
}

.select:not(.is-multiple):not(.is-loading):after {
  border-width: 2px;
  border-radius: 0;
}

.control.has-icons-left .icon.is-small {
  font-size: inherit;
  width: 1.5em;
  height: 1.5em;
}

.control.has-icons-left .icon.is-left {
  top: 50%;
  left: .5em;
  transform: translateY(-50%);
}

.control.has-icons-right .icon.is-small {
  font-size: inherit;
  width: 1.5em;
  height: 1.5em;
}

.control.has-icons-right .icon.is-right {
  top: 50%;
  right: .5em;
  transform: translateY(-50%);
}

.modal.is-active {
  animation: .5s forwards modal-in;
}

@keyframes modal-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-card {
  border-radius: 20px;
  max-width: 90vw;
  box-shadow: 0 0 20px #6a3e021a;
}

.modal--close {
  position: fixed;
  top: 5px;
  right: 5px;
}

@media screen and (width <= 979px) {
  .navbar-menu {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 20px #6a3e021a;
  }
}

.navbar-dropdown {
  font-size: 1.6rem;
}

.navbar-item, .navbar-link {
  padding: 0;
  transition: all .25s ease-in-out;
}

@media screen and (width >= 980px) {
  .navbar-item, .navbar-link {
    padding: 0;
  }
}

.navbar-link.button:hover {
  color: inherit;
  box-shadow: none;
}

.navbar-link.button:hover:before {
  background-color: inherit;
}

.navbar-link.button:hover .icon {
  background-color: #e78704;
}

html {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

body {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  min-width: 980px;
  height: 100%;
}

body.bg-primary-gradient-soft {
  background: linear-gradient(#e787041a, #5bc72b1a);
}

body.bg-primary-gradient-hard {
  background: linear-gradient(#e7870440, #5bc72b40);
}

#root {
  height: 100%;
}

.header {
  z-index: 30;
  background-color: #fff;
  width: 100vw;
  height: 50px;
  padding: 0 15px;
  line-height: 1;
  position: fixed;
  top: 0;
  right: 0;
}

.layout--test .header {
  border-left: 2px solid #e787041a;
  width: 50vw;
}

.header__logo {
  margin: 0 5px;
  padding-top: 5px;
}

.is-timeout .header__logo {
  display: none;
}

.header__title {
  color: #000;
  margin: 0 5px;
}

.is-timeout .header__title {
  display: none;
}

.header__title p {
  justify-content: flex-start;
  align-items: baseline;
  gap: 0 5px;
  display: flex;
}

.header__name {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 700;
}

.header__subname {
  margin-left: 2.5px;
  font-size: 1.8rem;
}

.layout--test .header__subname {
  display: none;
}

@media screen and (width >= 1172px) {
  .layout--test .header__subname {
    display: inline-block;
  }
}

.header__version {
  color: #7f7f7f;
  margin-left: 2.5px;
  font-size: 1.2rem;
}

.layout--test .header__version {
  display: none;
}

@media screen and (width >= 1172px) {
  .layout--test .header__version {
    display: inline-block;
  }
}

.header__timeout {
  color: #d30000;
  margin: 0 5px;
  display: none;
}

.is-timeout .header__timeout {
  display: block;
}

.header__timeout p strong {
  color: #d30000;
}

.header__launch {
  margin: 0 5px;
  display: flex;
}

.is-timeout .header__launch {
  display: none;
}

.layout {
  position: absolute;
}

.layout--centered .layout {
  width: 100vw;
  height: calc(100% - 50px);
  position: relative;
  top: 50px;
  overflow: auto;
}

.layout--centered .layout h1:not(:last-child), .layout--centered .layout h2:not(:last-child), .layout--centered .layout h3:not(:last-child), .layout--centered .layout h4:not(:last-child), .layout--centered .layout p:not(:last-child), .layout--centered .layout ul:not(:last-child), .layout--centered .layout ol:not(:last-child), .layout--centered .layout .image:not(:last-child), .layout--centered .layout .buttons:not(:last-child), .layout--centered .layout .controls:not(:last-child) {
  margin-bottom: 20px;
}

.layout--centered .layout h1 + p, .layout--centered .layout h2 + p, .layout--centered .layout h3 + p, .layout--centered .layout h4 + p {
  margin-top: -10px;
}

.layout--test .layout {
  flex-wrap: nowrap;
  height: 100%;
  display: flex;
  top: 0;
  overflow: hidden;
}

.layout__left {
  width: 50vw;
  height: calc(100% - 50px);
  position: relative;
}

.layout__left:before {
  z-index: -10;
  content: "";
  opacity: 0;
  visibility: hidden;
  background: url("loading-bird.5402b9cc.svg") 0 0 / 48px 48px no-repeat;
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.test--out .layout__left:before {
  visibility: visible;
  animation: 1s ease-in-out .5s forwards loading-spinner-fade-in, 1s ease-in-out .5s infinite loading-spinner-pulsate;
}

.layout--test .layout__left {
  height: 100%;
}

.layout__right {
  width: 50vw;
  height: calc(100% - 50px);
  position: relative;
}

.layout__right:before {
  z-index: -10;
  content: "";
  opacity: 0;
  visibility: hidden;
  background: url("loading-bird.5402b9cc.svg") 0 0 / 48px 48px no-repeat;
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.test--out .layout__right:before {
  visibility: visible;
  animation: 1s ease-in-out .5s forwards loading-spinner-fade-in, 1s ease-in-out .5s infinite loading-spinner-pulsate;
}

.layout--test .layout__right {
  margin-top: 50px;
}

.bg-primary-gradient-soft {
  background: linear-gradient(#e787041a, #5bc72b1a);
}

.bg-primary-gradient-hard {
  background: linear-gradient(#e7870440, #5bc72b40);
}

.metabg {
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: calc(100% - 50px);
  display: flex;
}

.layout--test .metabg {
  height: 100%;
}

.test--in .metabg {
  animation: .5s forwards test-in;
}

.test--out .metabg {
  animation: .5s forwards test-out;
}

.metabg .metabg__icon {
  background: #e78704;
  width: 48px;
  height: 48px;
}

.metabg .metabg__text {
  color: #e78704;
  margin: -48px 0 0 .5em;
  font-size: 1.2rem;
  position: relative;
}

.metabg .metabg__text span {
  display: block;
}

.metabg .metabg__text span:first-child {
  transform: rotate(-10deg);
}

.metabg .metabg__text span:last-child {
  position: absolute;
  top: 1em;
  left: 1.25em;
  transform: rotate(-4deg);
}

.story {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  flex-wrap: nowrap;
  justify-content: flex-end;
  display: flex;
  position: relative;
  transform: translateY(0);
}

.story:before, .story:after {
  z-index: 20;
  content: "";
  pointer-events: none;
  height: 50px;
  position: absolute;
  left: 0;
  right: 35px;
}

@media screen and (width >= 1172px) {
  .story:before, .story:after {
    right: 60px;
  }
}

.story:before {
  background: linear-gradient(#ffffffe6 0%, #fff0 100%);
  top: 0;
}

.story:after {
  background: linear-gradient(to top, #ffffffe6 0%, #fff0 100%);
  bottom: 0;
}

.test--in .story {
  animation: .5s forwards test-in;
}

.test--out .story {
  animation: .5s forwards test-out;
}

.story__scrolling {
  z-index: 10;
  scroll-behavior: smooth;
  width: 100%;
  max-width: 525px;
  height: 100vh;
  margin: 0 0 0 auto;
  padding: 0 25px;
  transition: all .25s ease-in-out;
  overflow-y: auto;
}

@media screen and (width >= 1364px) {
  .story__scrolling {
    max-width: 600px;
    padding: 0 50px;
  }
}

.simplebar-wrapper {
  margin: 0 !important;
}

.simplebar-content {
  padding-right: 60px !important;
}

@media screen and (width >= 1364px) {
  .simplebar-content {
    padding-right: 110px !important;
  }
}

.simplebar-track.simplebar-vertical {
  cursor: pointer;
  width: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  pointer-events: auto !important;
}

@media screen and (width >= 1364px) {
  .simplebar-track.simplebar-vertical {
    right: 50px;
  }
}

.simplebar-track.simplebar-vertical:before {
  content: "";
  background-color: #f2f2f2;
  width: 2px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
}

.simplebar-scrollbar:before {
  opacity: 1;
  background: #e78704;
  left: 3px;
  right: 3px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.story__header {
  padding: 50px 0;
  transition: all .25s ease-in-out;
  position: relative;
}

.story__title {
  margin-bottom: 0;
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-weight: 700;
}

.little-info {
  color: #7f7f7f;
  font-size: 1.2rem;
}

.story__text {
  margin-bottom: 150px;
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-size: 1.8rem;
  font-weight: 400;
  transition: all .25s ease-in-out;
  position: relative;
}

.story__text h1, .story__text h2, .story__text h3, .story__text h4, .story__text h5, .story__text h6 {
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-weight: 700;
}

.qanda {
  background: linear-gradient(#e787041a, #5bc72b1a);
  height: 100%;
}

.layout--test .qanda {
  overflow-y: auto;
}

.qanda__header {
  color: #7f7f7f;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
  padding: 5px 25px 0;
  font-size: 1.2rem;
  display: flex;
}

.qanda__header .qanda__header__item {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0 5px;
  display: flex;
  position: relative;
}

.qanda__header .qanda__header__item:not(:last-child):after {
  content: "•";
  position: absolute;
  right: -10px;
  transform: translateX(50%);
}

.qanda__header--value {
  font-weight: 700;
}

.qanda__progress {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  width: 440px;
  margin: 25px 0 0 25px;
  line-height: 1;
  transition: all .5s ease-in-out;
  display: flex;
}

@media screen and (width >= 1172px) {
  .qanda__progress {
    margin-left: 75px;
  }
}

@media screen and (width <= 989px) {
  .layout--test .qanda__progress {
    display: none;
  }
}

.progress-caption {
  color: #5bc72b;
  width: 3.5em;
  font-size: 1.2rem;
  font-weight: bold;
  display: block;
}

.progress-caption:first-child {
  text-align: right;
}

.progress-steps {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  height: 24px;
  display: flex;
  position: relative;
}

.progress-step {
  width: 8px;
  height: 8px;
  display: block;
  position: relative;
}

.progress-step:before {
  z-index: 10;
  content: "";
  background-color: #bfbfbf;
  border-radius: 290486px;
  width: 8px;
  height: 8px;
  transition: all .125s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-step:after {
  z-index: 5;
  content: "";
  pointer-events: none;
  background-color: #fff;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 8px;
}

.progress-step:not(:last-child):after {
  width: 20px;
}

.progress-step:hover {
  cursor: pointer;
}

.progress-step:hover:before {
  background-color: #7f7f7f;
}

.progress-step.progress--done:before {
  background-color: #5bc72b;
}

.progress-step.progress--done:after {
  animation: 2s forwards step-done;
}

.progress-step.progress--done .progress-bird {
  animation: 2s step-done-bird;
}

.progress-step.progress--active .progress-bird {
  animation: 0s 2s forwards step-active-bird;
}

.progress-step.progress--active:hover .progress-bird {
  transform: translate(-35%, -35%)scale(1.1);
}

.progress-bird {
  z-index: 20;
  opacity: 0;
  background: url("progress-bird.9d7d86ec.svg") no-repeat;
  width: 24px;
  height: 24px;
  transition: all .125s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-35%, -35%)scale(1);
}

@keyframes step-done {
  0% {
  }

  100% {
    background-color: #5bc72b;
  }
}

@keyframes step-done-bird {
  0% {
    opacity: 1;
    left: 0;
  }

  100% {
    opacity: 1;
    left: 28px;
  }
}

@keyframes step-active-bird {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.qanda__bubbles {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 440px;
  margin: 25px 25px 0;
  line-height: 1;
  transition: all .5s ease-in-out;
  display: flex;
  transform: translateY(0);
}

@media screen and (width <= 989px) {
  .layout--test .qanda__bubbles {
    width: calc(100% - 50px);
  }
}

@media screen and (width >= 1172px) {
  .qanda__bubbles {
    margin-left: 75px;
  }
}

.test--in .qanda__bubbles {
  animation: .5s forwards test-in;
}

.test--out .qanda__bubbles {
  animation: .5s forwards test-out;
}

.qanda__bubble {
  z-index: 10;
  text-align: center;
  color: #bfbfbf;
  cursor: default;
  background: #fff;
  border: 2px solid #0000;
  border-radius: 290486px;
  width: auto;
  min-width: calc(1.2em + 4px);
  margin: 0 5px;
  padding: .1em;
  font-size: 1.4rem;
  transition: all .5s ease-in-out;
  display: block;
  position: relative;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__bubble {
    font-size: 1.2rem;
  }
}

.qanda__bubble:after {
  content: "";
  z-index: -10;
  opacity: 0;
  background: #fff;
  width: 2px;
  height: 25px;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translateX(-50%);
}

.qanda__bubble.qanda__bubble--answered {
  color: #000;
}

.qanda__bubble.qanda__bubble--active {
  color: #000;
  border-color: #000;
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.qanda__bubble.qanda__bubble--active:after {
  opacity: 1;
}

.qanda__cards {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  width: 100%;
  height: auto;
  transition: all .5s ease-in-out;
  overflow: hidden;
  transform: translateY(0);
}

.qanda__cards::-webkit-scrollbar {
  display: none;
}

.qanda__cards + .qanda__bubbles, .qanda__cards + .qanda__progress {
  margin-top: 0;
}

.test--in .qanda__cards {
  animation: .5s forwards test-in;
}

.test--out .qanda__cards {
  animation: .5s forwards test-out;
}

.qanda__cards-holder {
  flex-wrap: nowrap;
  align-items: stretch;
  gap: 0 25px;
  width: auto;
  height: auto;
  margin: 0;
  padding-left: 25px;
  transition: all .5s ease-in-out;
  display: flex;
  position: relative;
  left: 0;
}

@media screen and (width >= 1172px) {
  .qanda__cards-holder {
    padding-left: 75px;
  }
}

.qanda__cards-holder.is-multiple > .qanda__card {
  box-shadow: none;
  opacity: .75;
}

.qanda__cards-holder.is-multiple > .qanda__card > * {
  display: none;
}

.qanda__cards-holder.is-multiple > .qanda__card:hover {
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-0 {
  left: 0;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-0 {
    left: 0;
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-0 .card-id-0 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-0 .card-id-0 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-0 .card-id-0:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-1 {
  left: -465px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-1 {
    left: calc(25px - 100%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-1 .card-id-1 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-1 .card-id-1 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-1 .card-id-1:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-2 {
  left: -930px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-2 {
    left: calc(50px - 200%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-2 .card-id-2 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-2 .card-id-2 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-2 .card-id-2:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-3 {
  left: -1395px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-3 {
    left: calc(75px - 300%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-3 .card-id-3 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-3 .card-id-3 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-3 .card-id-3:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-4 {
  left: -1860px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-4 {
    left: calc(100px - 400%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-4 .card-id-4 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-4 .card-id-4 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-4 .card-id-4:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-5 {
  left: -2325px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-5 {
    left: calc(125px - 500%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-5 .card-id-5 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-5 .card-id-5 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-5 .card-id-5:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-6 {
  left: -2790px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-6 {
    left: calc(150px - 600%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-6 .card-id-6 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-6 .card-id-6 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-6 .card-id-6:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-7 {
  left: -3255px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-7 {
    left: calc(175px - 700%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-7 .card-id-7 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-7 .card-id-7 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-7 .card-id-7:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-8 {
  left: -3720px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-8 {
    left: calc(200px - 800%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-8 .card-id-8 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-8 .card-id-8 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-8 .card-id-8:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-9 {
  left: -4185px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-9 {
    left: calc(225px - 900%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-9 .card-id-9 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-9 .card-id-9 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-9 .card-id-9:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-10 {
  left: -4650px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-10 {
    left: calc(250px - 1000%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-10 .card-id-10 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-10 .card-id-10 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-10 .card-id-10:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-11 {
  left: -5115px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-11 {
    left: calc(275px - 1100%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-11 .card-id-11 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-11 .card-id-11 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-11 .card-id-11:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-12 {
  left: -5580px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-12 {
    left: calc(300px - 1200%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-12 .card-id-12 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-12 .card-id-12 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-12 .card-id-12:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-13 {
  left: -6045px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-13 {
    left: calc(325px - 1300%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-13 .card-id-13 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-13 .card-id-13 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-13 .card-id-13:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-14 {
  left: -6510px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-14 {
    left: calc(350px - 1400%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-14 .card-id-14 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-14 .card-id-14 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-14 .card-id-14:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-15 {
  left: -6975px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-15 {
    left: calc(375px - 1500%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-15 .card-id-15 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-15 .card-id-15 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-15 .card-id-15:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-16 {
  left: -7440px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-16 {
    left: calc(400px - 1600%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-16 .card-id-16 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-16 .card-id-16 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-16 .card-id-16:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-17 {
  left: -7905px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-17 {
    left: calc(425px - 1700%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-17 .card-id-17 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-17 .card-id-17 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-17 .card-id-17:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-18 {
  left: -8370px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-18 {
    left: calc(450px - 1800%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-18 .card-id-18 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-18 .card-id-18 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-18 .card-id-18:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-19 {
  left: -8835px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-19 {
    left: calc(475px - 1900%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-19 .card-id-19 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-19 .card-id-19 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-19 .card-id-19:hover {
  cursor: default;
}

.qanda__cards-holder.is-multiple.is-active-card-id-20 {
  left: -9300px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__cards-holder.is-multiple.is-active-card-id-20 {
    left: calc(500px - 2000%);
  }
}

.qanda__cards-holder.is-multiple.is-active-card-id-20 .card-id-20 {
  opacity: 1;
  box-shadow: 0 0 20px #6a3e021a;
}

.qanda__cards-holder.is-multiple.is-active-card-id-20 .card-id-20 > * {
  display: inherit;
}

.qanda__cards-holder.is-multiple.is-active-card-id-20 .card-id-20:hover {
  cursor: default;
}

.qanda__card {
  opacity: 1;
  background-color: #fff;
  border-radius: 20px;
  width: 440px;
  min-width: 440px;
  max-width: 440px;
  margin-top: 25px;
  margin-bottom: 25px;
  transition: all .25s ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 20px #6a3e021a;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__card {
    width: calc(100% - 25px);
    min-width: calc(100% - 25px);
    max-width: calc(100% - 25px);
  }
}

.qanda__card > * {
  transition: all .5s ease-in-out;
}

.qanda__card > :not(:first-child) {
  border-top: 2px solid #e6e6e6;
}

.qanda__intro {
  padding: 20px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__intro {
    padding: 10px;
  }
}

.qanda__form:not(:first-child) {
  border-top: 2px solid #e6e6e6;
}

.qanda__card--meta .qanda__form--active {
  background-color: #f2fbee;
}

.qanda__question {
  padding: 20px 20px 10px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__question {
    padding: 10px;
  }
}

.qanda__question__text {
  font-weight: bold;
}

.qanda__card--test .qanda__question__text {
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-weight: 700;
}

.qanda__question__subtext {
  color: #7f7f7f;
}

.qanda__card--test .qanda__answers {
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-weight: 400;
}

.qanda__checkradio {
  cursor: pointer;
  flex-wrap: nowrap;
  padding: 5px 20px;
  display: flex;
  position: relative;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__checkradio {
    padding: 5px 10px;
  }
}

.qanda__checkradio:last-child {
  padding-bottom: 20px !important;
}

.qanda__card--test .qanda__checkradio {
  border-top: 1px solid #e6e6e6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qanda__card--test .qanda__checkradio:hover {
  background-color: #fffbf5;
}

.qanda__checkradio__input {
  opacity: 0;
  position: absolute;
}

.qanda__checkradio__input:checked + .qanda__checkradio__box {
  background-color: #e78704;
}

.qanda__checkradio__input:checked + .qanda__checkradio__box:after {
  opacity: 0;
  transition: all .25s ease-out;
  box-shadow: 0 0 20px 20px #e78704;
}

.qanda__checkradio__input:checked + .qanda__checkradio__box .icon {
  opacity: 1;
}

.qanda__checkradio__box {
  border: 2px solid #e78704;
  border-radius: 5px;
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  margin: 2.5px 10px 0 0;
  position: relative;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__checkradio__box {
    width: 15px;
    min-width: 15px;
    max-width: 15px;
    height: 15px;
  }
}

.qanda__checkradio__box:after {
  content: "";
  opacity: 1;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  transition: all;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 #e78704;
}

.radio .qanda__checkradio__box {
  border-radius: 290486px;
}

.qanda__card--test .qanda__checkradio__box {
  margin-right: 20px;
}

@media screen and (width <= 989px) {
  .layout--test .qanda__card--test .qanda__checkradio__box {
    margin-right: 10px;
  }
}

.qanda__checkradio__box .icon {
  opacity: 0;
  background-color: #fff;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (width <= 989px) {
  .layout--test .qanda__checkradio__box .icon {
    width: 15px;
    height: 15px;
  }
}

.qanda__card--test .qanda__checkradio--active {
  background-color: #fff6eb;
}

.qanda__card--test .qanda__checkradio--active + .qanda__checkradio--active {
  border-top-color: #efefef;
}

.qanda__checks--2columns .qanda__answers, .qanda__radios--2columns .qanda__answers {
  flex-wrap: wrap;
  display: flex;
}

.qanda__checks--2columns .qanda__checkradio, .qanda__radios--2columns .qanda__checkradio {
  border-top: none;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.qanda__card--test .qanda__checks--2columns .qanda__checkradio__box, .qanda__card--test .qanda__radios--2columns .qanda__checkradio__box {
  margin-right: 10px;
}

.qanda__birthday .qanda__answers {
  padding: 5px 20px 20px;
}

.qanda__birthmonthyear:first-child {
  margin-right: 10px;
}

.qanda__controls {
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  width: 440px;
  min-width: 440px;
  max-width: 440px;
  margin: 0 25px 100px;
  transition: all .5s ease-in-out;
  transform: translateY(0);
}

@media screen and (width <= 989px) {
  .layout--test .qanda__controls {
    width: calc(100% - 50px);
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
}

@media screen and (width >= 1172px) {
  .qanda__controls {
    margin: 0 75px 100px;
  }
}

.test--in .qanda__controls {
  animation: .5s forwards test-in;
}

.test--out .qanda__controls {
  animation: .5s forwards test-out;
}

.qanda__backforward {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.qanda__backforward + .qanda__complete {
  margin-top: 25px;
}

.qanda__complete {
  text-align: center;
}

.qanda__complete .little-info {
  margin-bottom: 10px;
}

.tree__show {
  flex-wrap: nowrap;
  justify-content: center;
  gap: 0 25px;
  display: flex;
}

.tree__show.tree-home {
  align-items: center;
}

@media screen and (width >= 980px) {
  .tree__show {
    gap: 0 50px;
  }
}

.tree__left {
  width: 50%;
  height: 500px;
  position: relative;
}

.tree-image {
  z-index: 10;
  background: url("tree.b8b3db28.svg") 50% 0 / 250px 500px no-repeat;
  width: 250px;
  height: 500px;
  margin: 0 auto;
  position: relative;
}

.tree-scales {
  z-index: 5;
}

.tree-scale {
  color: #7f7f7f;
  width: 100%;
  height: 1.5em;
  font-size: 1.2rem;
  position: absolute;
}

.tree-scale:after {
  content: "";
  background: linear-gradient(to right, #7f7f7f40, #7f7f7f00);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 50%;
}

.tree-scale-result {
  bottom: calc(var(--result) * 1px * 4.3 - 110px);
  text-align: right;
  color: #5bc72b;
  font-weight: bold;
}

.tree-scale-result:after {
  background: linear-gradient(to right, #5bc72b00, #5bc72b);
  left: 50%;
  right: 0;
}

.tree-scale-40 {
  bottom: 62px;
}

.tree-scale-60 {
  bottom: 148px;
}

.tree-scale-80 {
  bottom: 234px;
}

.tree-scale-100 {
  bottom: 320px;
}

.tree-scale-120 {
  bottom: 406px;
}

.tree-scale-140 {
  bottom: 492px;
}

.tree-bird {
  z-index: 20;
  background: url("progress-bird.9d7d86ec.svg") 0 0 / 16px 16px no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 50%;
}

.tree-bird-result {
  bottom: calc(var(--result) * 1px * 4.3 - 110px - 48px * .5);
  background-size: 48px 48px;
  width: 48px;
  height: 48px;
}

.tree-bird-1 {
  top: 20px;
  transform: translateX(-25px);
}

.tree-bird-2 {
  top: -5px;
  transform: translateX(15px);
}

.tree-bird-3 {
  top: 90px;
  transform: translateX(-80px);
}

.tree-bird-4 {
  top: 115px;
  transform: translateX(-30px);
}

.tree-bird-5 {
  top: 100px;
  transform: translateX(60px);
}

.tree-bird-6 {
  top: 160px;
  transform: translateX(95px);
}

.tree-bird-7 {
  top: 205px;
  transform: translateX(75px);
}

.tree-bird-8 {
  top: 185px;
  transform: translateX(-100px);
}

.tree-bird-9 {
  top: 200px;
  transform: translateX(-55px);
}

.tree-bird-10 {
  top: 335px;
  transform: translateX(75px);
}

.tree-bird-11 {
  top: 260px;
  transform: translateX(-40px);
}

.tree-bird-12 {
  top: 345px;
  transform: translateX(105px);
}

.tree-bird-13 {
  top: 390px;
  transform: translateX(65px);
}

.tree-text {
  z-index: -50;
  color: #f2f2f2;
  -webkit-user-select: none;
  user-select: none;
  font-family: Gelasio, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  font-size: 3.2rem;
  font-weight: 400;
  position: absolute;
  inset: -60px -10px;
  overflow: hidden;
  transform: rotate(-3deg);
}

.tree__right {
  width: 50%;
  position: relative;
}

.tree__right .text-move {
  bottom: calc(var(--result) * 1px * 4.3 - 110px);
  position: absolute;
  transform: translateY(65%);
}

.tree__right .text-points {
  color: #5bc72b;
  font-family: Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  display: block;
}

@media screen and (width >= 980px) {
  .tree__right .text-points {
    font-size: 4.8rem;
  }
}

.result-name {
  display: none;
}

.result-info {
  font-size: 1.2rem;
}

.helper-trigger {
  color: #00b3cc;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.helper-trigger .licon-info {
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
}

.__react_component_tooltip {
  border-radius: 20px !important;
  padding: .5em 1em !important;
  font-size: 1.2rem !important;
  box-shadow: 0 0 20px #6a3e021a !important;
}

.snackbars {
  z-index: 50;
  pointer-events: none;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.snackbar {
  opacity: 0;
  max-width: calc(100vw - 40px);
  margin-top: -25px;
  padding: 10px 20px;
  animation: 10s ease-out forwards notification-life-cycle;
  transform: translateY(calc(100% + 10px));
  box-shadow: 0 0 20px #6a3e021a;
}

@keyframes notification-life-cycle {
  0% {
    opacity: 0;
    margin-top: -25px;
    transform: translateY(calc(100% + 10px));
  }

  5%, 95% {
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    margin-top: 0;
    transform: translateY(0);
  }
}

.is-small {
  font-size: 1.2rem;
}

.is-normal {
  font-size: 1.6rem;
}

.is-medium {
  font-size: 2.4rem;
}

.is-large {
  font-size: 3.2rem;
}

.is-info {
  color: #00b3cc;
}

.is-success {
  color: #5bc72b;
}

@media print {
  @page {
    height: 29.7cm;
    width: 21cm;
    margin: 0;
    background-color: #e7f8df;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  * {
    filter: none !important;
    -ms-filter: none !important;
    transition: none !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
  }

  audio, object, embed, video, noscript, #header, .snackbars, .modals {
    display: none;
  }

  html, body {
    overflow: inherit;
    width: 21cm;
    height: 100%;
  }

  html {
    font-size: 10px !important;
  }

  body {
    min-height: 29.7cm;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  body.layout--result {
    background-color: #5bc72b1a;
  }

  .layout {
    page-break-before: avoid;
    page-break-inside: allow;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .layout:before {
    content: "";
    background: #fff url("logo_print.ee3627ee.svg") 50% / 6.5cm .65cm no-repeat;
    width: 100%;
    height: 2.65cm;
    margin: 0;
    display: block;
  }

  .layout--centered .layout {
    width: 100%;
    height: auto;
    top: 0;
  }

  .section {
    page-break-inside: allow;
    padding: 1cm 0;
  }

  .section.bg-primary-gradient-soft {
    background: none;
  }

  .container {
    padding-left: 4cm;
    padding-right: 4cm;
    max-width: none !important;
  }

  .section-result-headline, .section-result-output .licon-medal, .section-result-output .licon-user {
    display: none;
  }

  .tree__show {
    transform: scale(.75);
    margin: -1cm 0 !important;
  }

  .tree__show .tree-scale:after {
    background: #7f7f7f1a;
  }

  .tree__show .tree-scale-result:after {
    background: #5bc72b80;
  }

  .tree__show .button {
    display: none;
  }

  .result-name {
    display: block;
  }

  .section-result-controls {
    display: none;
  }
}

.layout--result-print {
  background-color: #e7f8df;
  width: 21cm;
  height: 29.7cm;
  min-height: 29.7cm;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.layout--result-print * {
  filter: none !important;
  -ms-filter: none !important;
  transition: none !important;
}

.layout--result-print pre, .layout--result-print blockquote {
  page-break-inside: avoid;
}

.layout--result-print audio, .layout--result-print object, .layout--result-print embed, .layout--result-print video, .layout--result-print noscript, .layout--result-print #header, .layout--result-print .snackbars, .layout--result-print .modals {
  display: none;
}

.layout--result-print .layout {
  page-break-before: avoid;
  page-break-inside: allow;
  width: 100%;
  height: 29.7cm;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  top: 0;
}

.layout--result-print .layout:before {
  content: "";
  background: #fff url("logo_print.ee3627ee.svg") 50% / 6.5cm .65cm no-repeat;
  width: 100%;
  height: 2.65cm;
  margin: 0;
  display: block;
}

.layout--result-print .section {
  page-break-inside: allow;
  padding: 1cm 0;
}

.layout--result-print .section.bg-primary-gradient-soft {
  background: none;
}

.layout--result-print .container {
  padding-left: 4cm;
  padding-right: 4cm;
  max-width: none !important;
}

.layout--result-print .section-result-headline, .layout--result-print .section-result-output .licon-medal, .layout--result-print .section-result-output .licon-user {
  display: none;
}

.layout--result-print .tree__show {
  transform: scale(.75);
  margin: -1cm 0 !important;
}

.layout--result-print .tree__show .tree-scale:after {
  background: #7f7f7f1a;
}

.layout--result-print .tree__show .tree-scale-result:after {
  background: #5bc72b80;
}

.layout--result-print .tree__show .button {
  display: none;
}

.layout--result-print .result-name {
  display: block;
}

.layout--result-print .section-result-controls {
  display: none;
}
/*# sourceMappingURL=index.dddff847.css.map */
